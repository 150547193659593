import axios from '../../../serverAxios'
import { apiUrl, errorObj } from '../../../settings'
import { getToken } from '../../../request'

export const AddPurchaseOrderAction = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/purchaseOrder`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const ListAction = data => {
  return new Promise(async next => {
    await axios
      .get(`${apiUrl}/purchaseOrder`, { params: { ...data }, ...getToken() })
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const SearchPOToReport = data => {
  return new Promise(async next => {
    await axios
        .post(`${apiUrl}/searchPOToReport`, data, getToken())
        .then(x => {
          return next(x.data)
        })
        .catch(error => {
          return next({...errorObj, message: error})
        })
  })
}
export const NextPurchaseOrderIdAction = data => {
  return new Promise(async next => {
    await axios
      .get(`${apiUrl}/nextPurchaseOrderId`, {
        params: { ...data },
        ...getToken()
      })
        .then(x => {
          return next(x.data)
        })
        .catch(error => {
          return next({...errorObj, message: error})
        })
  })
}
export const GetPurchaseOrderAction = ({id, onlyPending}) => {
  return new Promise(async next => {
      let paramObj = {}
      if (onlyPending) {
          paramObj.onlyPending = true;
      }
    await axios
        .get(`${apiUrl}/purchaseOrder/${id}`, {params: paramObj, ...getToken()})
        .then(x => {
          return next(x.data)
        })
        .catch(error => {
          return next({...errorObj, message: error})
        })
  })
}
export const UpdatePurchaseOrderAction = data => {
  return new Promise(async next => {
    await axios
      .put(`${apiUrl}/purchaseOrder`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const AssignSupplierToPO = (_ids, data) => {
  return new Promise(async next => {
    await axios
      .put(`${apiUrl}/assignSupplier/PurchaseOrder`, { _ids, data }, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const AddPOItemAction = (data) => {
    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/purchase/addDeleteItem`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}


export const UpdatePurchaseOrderItem = (data) => {
    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/update/purchaseOrderItem`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const getDistributedOrderedDetailsAction = (data) => {
    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/distributed/OrderDetails`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const DeletePurchaseOrder = data => {
    return new Promise(async next => {
        await axios
            .delete(`${apiUrl}/purchaseOrder/` + data._id, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({ ...errorObj, message: error })
            })
    })
}
export const getPurechaseOrderItemListAction = (data) => {
  return new Promise(async next => {
      await axios
          .post(`${apiUrl}/purchaseOrder/orderedItemsList`, data, getToken())
          .then(x => {
              return next(x.data)
          })
          .catch(error => {
              return next({...errorObj, message: error})
          })
  })
}
export const getPurchaseOrderDispatchList = (data) => {
    return new Promise(async (next) => {
        await axios.post(`${apiUrl}/purchaseOrder/dispatchedItemList`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}

export const getPurechaseUnOrderItemListAction = (data) => {
  return new Promise(async next => {
      await axios
          .post(`${apiUrl}/purchaseOrder/urOrderedItemsList`, data, getToken())
          .then(x => {
              return next(x.data)
          })
          .catch(error => {
              return next({...errorObj, message: error})
          })
  })
}