import axios from '../../../serverAxios'
import { apiUrl, errorObj } from '../../../settings'
import { getToken } from '../../../request'

export const AddAction = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/supplier`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const AddAddressAction = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/supplier/address`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const EditAddressAction = data => {
  return new Promise(async next => {
    await axios
      .put(`${apiUrl}/supplier/address`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const ListAction = data => {
  return new Promise(async next => {
    await axios
      .get(`${apiUrl}/supplier`, { params: { ...data }, ...getToken() })
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const UpdateAction = data => {
  return new Promise(async next => {
    await axios
      .put(`${apiUrl}/supplier`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const StateListAction = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/list/state`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const NextSupplierIdAction = data => {
  return new Promise(async next => {
    await axios
      .get(`${apiUrl}/nextSupplierId`, { params: { ...data }, ...getToken() })
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const SearchSupplierByName = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/searchSupplierByName`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const DeleteSupplier = data => {
  return new Promise(async next => {
    await axios
      .delete(`${apiUrl}/supplier/` + data._id, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}


export const SearchSupplierToReport = data => {
  return new Promise(async next => {
    await axios
        .post(`${apiUrl}/searchSupplierToReport`, data, getToken())
        .then(x => {
          return next(x.data)
        })
        .catch(error => {
          return next({...errorObj, message: error})
        })
  })
}
export const SearchPOToReport = data => {
  return new Promise(async next => {
    await axios
        .post(`${apiUrl}/searchPOToReport`, data, getToken())
        .then(x => {
          return next(x.data)
        })
        .catch(error => {
          return next({...errorObj, message: error})
        })
  })
}
export const SearchPOToProjection = data => {
    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/searchPOToProjection`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const searchProductToReport = data => {
  return new Promise(async next => {
    await axios
        .post(`${apiUrl}/searchProductToReport`, data, getToken())
        .then(x => {
          return next(x.data)
        })
        .catch(error => {
          return next({...errorObj, message: error})
        })
  })
}
export const SearchProductForProjection = data => {
    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/searchProductForProjection`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const SearchVariantForProjection = data => {
    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/searchVariantForProjection`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}

export const SearchColorsForVariant = data => {
    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/searchColorsForVariant`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}


export const SearchSupplierToCPR = data => {
    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/SearchSupplierToCPR`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const SearchPOToCPR = data => {
    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/SearchPOToCPR`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const searchProductToCPR = data => {
    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/searchProductToCPR`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}


export const searchChallanToCPR = data => {
    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/searchChallanToCPR`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}

export const StateWithCodeListAction = data => {
  return new Promise(async next => {
    await axios
      .get(`${apiUrl}/list/stateWithCode`, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const searchProductDispatchReport = data => {
  return new Promise(async next => {
    await axios
        .post(`${apiUrl}/searchProductDispatchReport`, data, getToken())
        .then(x => {
          return next(x.data)
        })
        .catch(error => {
          return next({...errorObj, message: error})
        })
  })
}