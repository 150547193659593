import { combineReducers } from 'redux'
import counter from './counter'
import theme from './theme'
import global from './global'
import projection from './../containers/projection/reducer'
import challan from './../containers/challan/reducer'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory as createHistory } from 'history'
export const history = createHistory()

export default combineReducers({
  counter,
  theme,
  global,
  projection,
  challan,
  router: connectRouter(history)

})
