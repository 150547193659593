import React from 'react'
import styles from './login.less'
import { Form, Input, Button, Checkbox, notification } from 'antd'

import Request from '../../request'
import { showLoader, hideLoader } from '../../modules/actions'

import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
class NormalLoginForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  formRef = React.createRef()

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(hideLoader())
  }

  handleSubmit = async valData => {
    const { dispatch } = this.props



    // dispatch(userActions(values))
    dispatch(showLoader())

    let x = await Request.login(valData)


    dispatch(hideLoader())

    if (!x.error) {
      localStorage.setItem('token', x.token)
      localStorage.setItem('user', JSON.stringify(x.user))

      dispatch({
        type: 'SET_CURRENT_USER',
        user: x.user
      })

      setTimeout(() => {
        dispatch(push('/'))
      }, 300)
    } else {
      notification.error({
        message: 'Invalid Login',
        description: x.message
      })
    }
  }

  render() {
    const { loading } = this.props
    return (
      <Form onFinish={this.handleSubmit} className={styles.main}>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'Please enter your email address!' }
          ]}>
          <Input
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Email address"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}>
          <Input
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Checkbox>Remember me</Checkbox>
          <a className={styles.forgot} href="">
            Forgot password
          </a>
          <Button
            type="primary"
            loading={loading}
            htmlType="submit"
            className={styles.button}>
            Log in
          </Button>
          {/*Or <a href="">register now!</a>*/}
        </Form.Item>
      </Form>
    )
  }
}

const WrappedNormalLoginForm = NormalLoginForm

const mapStateToProps = ({ counter, global }) => ({
  count: counter.count,
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedNormalLoginForm)
