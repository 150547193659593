const initialState = {
    projectionItems: [],
    customProjectionItems: []
}

export default (state = initialState, action) => {
    switch (action.type) {

        case 'SET_PROJECTION_ITEMS':
            return {
                ...state,
                projectionItems: action.data
            }
        case 'SET_CUSTOM_PROJECTION_ITEMS':
            return {
                ...state,
                customProjectionItems: action.data
            }


        default:
            return state
    }
}
