import React, {useEffect, useState, useRef} from 'react'
import {

    Button, Input,
    Card, Tag,
    Spin, Row, Col, Drawer, Select
} from 'antd'
import 'react-quill/dist/quill.snow.css'

import styles from './styles.less'

import _ from 'lodash'
import {GetSanitizedAttribute} from '../../attribute/action'
import {SearchVariantAction, SearchProductByName} from '../action'

import {TableComp} from 'sz-react-utils4'
import SearchProductComp from '../../../components/search/product'
import {ListUnitAction} from '../../unit/action'
import {PlusOutlined, ReloadOutlined} from '@ant-design/icons'

let tempProds = []
let unitArr = []
const {Option} = Select
const SearchProduct = (props) => {

    const [spinLoader, setSpinLoader] = useState(false)
    const [filter, setFilter] = useState(null)
    const [choosenArr, setChoosen] = useState([])
    const [tempArr, setArr] = useState([])
    const [tableKey, setTableKey] = useState()
    const tableRef = useRef()

    let [cols, setCols] = useState([
        {
            title: 'Product',
            key: 'productName',
            dataKey: 'productName',

            fixed: 'left'
        },

        {
            title: 'Colour',
            key: 'colour',
            dataKey: 'colour',

        },
        {
            title: 'Type',
            key: 'type',
            dataKey: 'type',

            render: (val, record) => {
                if (val == 'Product') {
                    return <Tag color={'green'}>Product</Tag>
                } else {
                    return <Tag color={'purple'}>Raw Material</Tag>

                }
            }
        },
        {
            title: 'Order Qty',
            key: 'orderQty',
            dataKey: 'orderQty',
            fixed: 'left',
            render: (val, record) => {
                return <Row>
                    <Col><Input type={'number'} className={styles.inputNumber} value={record.orderQty}
                                style={{width: 100}} min={0} onChange={(e) => {
                        record.orderQty = e.target.value
                        let tempp = _.clone(tempProds)
                        let output = _.find(tempp, (v) => {
                            return (record._id == v._id)
                        })

                        if (output) {
                            output.orderQty = e.target.value
                        } else {

                            tempp.push(record)
                        }

                        tempp = _.reject(tempp, (v) => !v.orderQty);
                        tempProds = tempp;
                        setChoosen(tempp)

                    }}/>
                    </Col><small style={{marginLeft: 10}}>{record.unitName}</small>
                    {/* {record && record.unitName && <Col className={styles.unitSep}> <Select
                        className={styles.dropInput}
                        size={'small'}
                        value={record.unitName}
                        dropdownMatchSelectWidth={false}
                        style={{width: 100}}
                        onChange={(e) => {
                            let findUnit = _.find(unitArr, vc => {

                                return vc._id == e
                            })

                            if (findUnit) {
                                record.unitId = e
                                record.unitName = findUnit.name
                            } else {
                                record.unitId = null
                                record.unitName = null
                            }
                            let tempp = _.cloneDeep(tempProds)
                            let output = _.filter(tempp, (v) => {
                                return record._id == v._id
                            })
                            if (output) {
                                setChoosen(tempp)
                            }

                        }}
                    >
                        {unitArr.map((v, k) => {
                            return (
                                <Option key={k} value={v._id}>
                                    {v.name}
                                </Option>
                            )
                        })}

                    </Select></Col>}*/}
                </Row>
            }
        }

    ])
    useEffect(() => {

        fetchUnits()

    }, [tableKey])
    const fetchUnits = async () => {
        let {data} = await ListUnitAction({
            results: 1000
        })
        if (data) {
            unitArr = data
        }
    }
    useEffect(() => {
        if (filter) {
            tableRef.current.reload()
        }

    }, [filter])

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {


            if (filter && filter.productId && filter.productId._id) {
                params.productId = filter.productId
                params.includeRawMaterial = false;
            } else {
                params.includeRawMaterial = props.includeRawMaterial
            }


            let x = await SearchVariantAction({
                ...params
                // regExFilters: ['name']
            })

            if (x) {

                if (x.data) {
                    setArr(x.data)

                }
                return resolve(x)


            }


        })
    }


    return (

        <Drawer title="Choose Raw Materials" width={1000} placement="right" onClose={() => {
            props.onClose()
        }
        } visible={true}>
            <Spin spinning={spinLoader}>
                <Card extra={<div>
                               <span className={styles.choosenCount}>
                                   {choosenArr && choosenArr.length ? `${choosenArr.length} Products Selected.` : null} </span>
                    {choosenArr && choosenArr.length ?
                        <Button className={styles.okbtn} onClick={(e) => {
                            if (choosenArr && choosenArr.length) {


                                props.onSeleced(choosenArr)
                                props.onClose()
                                tempProds = []
                            }
                        }}>Ok</Button> : null}
                </div>}>
                    {
                        !spinLoader ?
                            <TableComp
                                ref={tableRef}
                                key={tableKey}

                                pagination={{

                                    defaultPageSize: 500,
                                    pageSizeOptions: ['100', '250', '500', '1000'],
                                    showSizeChanger: true

                                }}
                                rowKey={x => x._id}
                                apiRequest={apiRequest}
                                extraProps={{scroll: {x: true}}}
                                columns={cols}/> : null}
                </Card>
            </Spin>
        </Drawer>

    )

}
export default SearchProduct




