import React, {useEffect, useRef, useState} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils4'
import {Card, Button, Input, Tooltip, Row, Col, Popconfirm, notification} from 'antd'
import {ListAction, PrintSupplierOrder, DeleteSupplierOrder} from '../actions/index'
import moment from 'moment'
import {useDispatch} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'
import {EditOutlined, PlusOutlined, DownloadOutlined, DeleteOutlined} from '@ant-design/icons'

import _ from 'lodash'
import SearchPO from "../../../components/search/po";
import SearchSupplier from "../../../components/search/supplier";
import {ResizableTitle} from "../../../settings";
import {dateFormat} from "../../../request";

const AllSupplierOrders = () => {
    const dispatch = useDispatch()
    const [total, setTotal] = useState(0)
    const [columns, setColumns] = useState([
        {
            title: 'Purchase Order Date',
            key: 'date',
            render: (val, record) => {

                return moment(val).format(dateFormat)
            },
            // sorter: true
        },

        {
            title: 'Purchase Order No',
            key: 'purchaseOrderNumber',
            dataIndex: 'purchaseOrderNumber',
            // searchTextName: 'purchaseOrderNumber'
        },
        {
            title: 'Order Number',
            key: 'orderNumber',
            dataIndex: 'orderNumber'
        },
        {
            title: 'Supplier Name',
            key: 'supplierName',
            dataIndex: 'supplierName',
            // searchTextName: 'supplierName'
        },
        {
            title: 'Total Quantity',
            key: 'totalQty',
            dataIndex: 'totalQty'
        },
        {
            title: 'Purpose',
            key: 'purpose',
            dataIndex: 'purpose',
            render: val => {
                return <>{_.startCase(val)}</>
            }
        },
        {
            title: 'Order Type',
            key: 'orderType',
            dataIndex: 'orderType',
            render: val => {
                return <>{_.startCase(val)}</>
            }
        },

        // {
        //   title: 'Unassigned Products',
        //   key: 'unassignedOrder',
        //   render: (val, record) => {
        //     if (val && val > 0) {
        //       return <a target={'_blank'} href={`/purchaseOrder/details?id=${record && record._id}`}>{val}</a>
        //     } else {
        //       return null
        //     }

        //   }
        // },
        // {
        //   title: 'Assigned Products',
        //   key: 'assignedOrder',
        //   render: (val, record) => {
        //     if (val && val > 0) {
        //       return <a target={'_blank'} href={`/purchaseOrder/details?id=${record && record._id}`}>{val}</a>
        //     } else {
        //       return null
        //     }

        //   }
        // },
        {
            key: 'actions',
            title: 'Actions',
            render: (text, record) => {
                return (
                    <React.Fragment>
                        <Tooltip title="Edit Supplier Order">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => {

                                    if (record.orderType == 'Piece Order') {
                                        dispatch(
                                            getUrlPushWrapper('supplierOrder.edit', {id: record._id})
                                        )
                                    } else {
                                        dispatch(
                                            getUrlPushWrapper('supplierOrder.editRaw', {id: record._id})
                                        )
                                    }

                                }}
                                icon={<EditOutlined/>}
                            />
                        </Tooltip>
                        <Tooltip title="Download Supplier Order">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                loading={
                                    loadingDownload.id == record._id && loadingDownload.loading
                                }
                                onClick={async () => {

                                    setLoadingDownload({id: record._id, loading: true})
                                    const data = await PrintSupplierOrder(record._id)
                                    if (data.success) {
                                        window.open(data.url)
                                    }
                                    setLoadingDownload({id: null, loading: false})

                                    dispatch({type: 'HIDE_BTN_LOADING'})
                                }}
                                icon={<DownloadOutlined/>}
                            />
                        </Tooltip>
                        <Tooltip title="Delete Order">
                            <Popconfirm
                                title="Are you sure delete this Order?"
                                onConfirm={() => {
                                    deleteOrder({_id: record._id})
                                }}
                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button
                                    shape="circle"
                                    size="small"
                                    type={'danger'}
                                    //loading={loader}
                                    style={{marginLeft: '5px'}}

                                ><DeleteOutlined/></Button>
                            </Popconfirm>

                        </Tooltip>
                    </React.Fragment>
                )
            }
        }
    ])
    const [filter, setFilter] = useState({
        supplierId: null,
        poDocId: null
    })
    const [loadingDownload, setLoadingDownload] = useState({
        id: null,
        loading: false
    })
    /*let [cols, setCols] = useState([
        {
          title: 'Product',
          key: 'productName',
          dataKey: 'productName',
          width: 100
        },
        { title: 'Code', key: 'productCode', dataKey: 'productCode', width: 100 },
        {
          title: 'Supplier Name',
          key: 'supplierName',
          dataIndex: 'supplierName',
          width: 150
        }


      ])*/
    /* let [arr, setArr] = useState([])
       let [visible, setVisible] = useState(false)
       let [drawerKey, setDrawerKey] = useState()*/
    let [dateFilter, setDateFilter] = useState()
    const table = useRef(null)

    useEffect(() => {

        if (table && table.current) {
            table.current.reload()
        }

    }, [filter])
    const apiRequest = params => {

        return new Promise(async resolve => {
            if (!params.sortField || !params.sortOrder) {
                params.sortField = 'date'
                params.sortOrder = 'descend'
            }

            //   if (dateFilter && dateFilter.length) {
            //     params.date = {
            //       $gte: dateFilter[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            //       $lt: dateFilter[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
            //     }
            //   }


            if (filter && filter.supplierId) {
                params.supplierId = filter.supplierId;
            }
            if (filter && filter.poDocId) {
                params.poDocId = filter.poDocId;
            }

            if (filter && filter.orderNumber) {
                params.orderNumber = filter.orderNumber;
            }


            let {data} = await ListAction({
                ...params

            })

            if (data && !data.error) {
                setTotal(data.total)
            }
            return resolve(data)
        })
    }


    useEffect(() => {
        // fetchAttributes()
    }, [])

    useEffect(
        () => {
            table.current.reload()
        },
        [dateFilter]
    )

    //   const fetchAttributes = async () => {
    //     let { success, data } = await GetSanitizedAttribute({ showInList: true })
    //     if (success, data) {
    //       cols.push({
    //         title: 'Qty',
    //         key: 'qty',
    //         width: 100,
    //         dataKey: 'qty'
    //       })
    //       _.each(data, (v) => {
    //         delete v.filters
    //         delete v.searchTextName
    //         cols.push({
    //           ...v
    //         })
    //       })

    //       setCols(cols)
    //     }
    //   }


    const handleResize = (index) => (_, {size}) => {
        const newColumnss = [...columns];
        newColumnss[index] = {...newColumnss[index], width: size.width};
        setColumns(newColumnss);
    };

    const mergeColumns = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width || 150,
            onResize: handleResize(index),
        }),
    }));

    const deleteOrder = async data => {
        let x = await DeleteSupplierOrder(data)

        table.current.reload()
        if (!x.error) {
            notification.success({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })
        } else {
            notification.error({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })
        }
    }
    return (
        <PageHeaderWrapper title={`All Supplier Orders (${total})`}>
            <Card bordered={true}>
                {/* <Row>
                    <Col span={24} style={{textAlign: 'right'}}>
                        <Button
                            type="primary"
                            icon={<PlusOutlined/>}
                            onClick={() => {
                                dispatch(getUrlPushWrapper('supplierOrder.add'))
                            }}>
                            Add New Supplier Order
                        </Button>
                    </Col>
                </Row>*/}

                <Row gutter={{md: 10}}>
                    <Col>
                        <label>Choose PO</label>
                        <SearchPO onSelect={(e) => {
                            setFilter({
                                ...filter,
                                poDocId: e._id
                            })

                        }}/>
                    </Col>
                    <Col>
                        <label>Choose Supplier</label>
                        <SearchSupplier isMeghnaOffice={false} onSelect={(e) => {
                            setFilter({
                                ...filter,
                                supplierId: e._id
                            })
                        }}/>
                    </Col>
                    <Col>
                        <label>Order Number</label>
                        <Input onChange={(e) => {
                            setFilter({
                                ...filter,
                                supplierId: e._id,
                                orderNumber: e.target.value
                            })
                        }}/>
                    </Col>

                </Row>

                <TableComp ref={table} columns={mergeColumns} extraProps={{

                    components: {
                        header: {
                            cell: ResizableTitle,
                        },
                    }
                }} apiRequest={apiRequest}/>
            </Card>
        </PageHeaderWrapper>
    )
}

export default AllSupplierOrders
