import axios from './serverAxios'

export default {
    setupInterceptors: (store, history) => {
        axios.interceptors.response.use(
            response => {
                return response

            },
            error => {
                if (error.response.status === 404 || error.response.status === 401) {
                    localStorage.clear()
                    store.dispatch({type: 'LOGOUT'})
                    history.push('/login')
                }
                
                return Promise.reject('Unauthorized access')
            }
        )
    }
}
