import axios from '../../../serverAxios'
import { apiUrl, errorObj } from '../../../settings'
import { getToken } from '../../../request'

export const AddUnitAction = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/unit`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const ListUnitAction = data => {
  return new Promise(async next => {
    await axios
      .get(`${apiUrl}/unit`, { params: { ...data }, ...getToken() })
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const UpdateUnitAction = data => {
  return new Promise(async next => {
    await axios
      .put(`${apiUrl}/unit`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const DeleteUnit = data => {
  return new Promise(async next => {
    await axios
      .delete(`${apiUrl}/unit/` + data._id, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

