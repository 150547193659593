import axios from '../../../serverAxios'
import { apiUrl, errorObj } from '../../../settings'
import { getToken } from '../../../request'

export const AddVariantAction = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/productVariant`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const UpdateProductVariationAction = data => {
  return new Promise(async next => {
    await axios
      .put(`${apiUrl}/productVariant`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const ListProductVariantsAction = data => {
  return new Promise(async next => {
    await axios
      .get(`${apiUrl}/productVariant`, { params: { ...data }, ...getToken() })
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const SearchVariantAction = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/searchVariant`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const SearchVariantForPOAction = data => {
  return new Promise(async next => {
    await axios
        .post(`${apiUrl}/searchVariantForPO`, data, getToken())
        .then(x => {
          return next(x.data)
        })
        .catch(error => {
          return next({...errorObj, message: error})
        })
  })
}
export const ListVariantAction = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/listVariant`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const SearchProductByName = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/searchProductByName`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const VariantChallanLedgarAction = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/variantChallanLedgar`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const GetProductVariantListAction = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/getProductVariantList`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const ProductLedgerAction = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/productLedger`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const ExportVariantChallanReportAction = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/variantChallanLedger/exportReport`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const DeleteVariant = data => {
  return new Promise(async next => {
    await axios
      .delete(`${apiUrl}/variant/` + data._id, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const VariationDispatchDetail = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/variant/dispatch/detail`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
