import moment from 'moment'
import React from 'react'
import { parse, stringify } from 'qs'

export function fixedZero (val) {
  return val * 1 < 10 ? `0${val}` : val
}

export function getTimeDistance (type) {
  const now = new Date()
  const oneDay = 1000 * 60 * 60 * 24

  if (type === 'today') {
    now.setHours(0)
    now.setMinutes(0)
    now.setSeconds(0)
    return [moment(now), moment(now.getTime() + (oneDay - 1000))]
  }

  if (type === 'week') {
    let day = now.getDay()
    now.setHours(0)
    now.setMinutes(0)
    now.setSeconds(0)

    if (day === 0) {
      day = 6
    } else {
      day -= 1
    }

    const beginTime = now.getTime() - day * oneDay

    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))]
  }

  if (type === 'month') {
    const year = now.getFullYear()
    const month = now.getMonth()
    const nextDate = moment(now).add(1, 'months')
    const nextYear = nextDate.year()
    const nextMonth = nextDate.month()

    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000)
    ]
  }

  const year = now.getFullYear()
  return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)]
}

export function getPlainNode (nodeList, parentPath = '') {
  const arr = []
  nodeList.forEach(node => {
    const item = node
    item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/')
    item.exact = true
    if (item.children && !item.component) {
      arr.push(...getPlainNode(item.children, item.path))
    } else {
      if (item.children && item.component) {
        item.exact = false
      }
      arr.push(item)
    }
  })
  return arr
}

function getRelation (str1, str2) {
  if (str1 === str2) {
    console.warn('Two path are equal!') // eslint-disable-line
  }
  const arr1 = str1.split('/')
  const arr2 = str2.split('/')
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1
  }
  if (arr1.every((item, index) => item === arr2[index])) {
    return 2
  }
  return 3
}

function getRenderArr (routes) {
  let renderArr = []
  renderArr.push(routes[0])
  for (let i = 1; i < routes.length; i += 1) {
    // 去重
    renderArr = renderArr.filter(item => getRelation(item, routes[i]) !== 1)
    // 是否包含
    const isAdd = renderArr.every(item => getRelation(item, routes[i]) === 3)
    if (isAdd) {
      renderArr.push(routes[i])
    }
  }
  return renderArr
}

export function getRoutes (path, routerData) {
  let routes = Object.keys(routerData).filter(
    routePath => routePath.indexOf(path) === 0 && routePath !== path
  )
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map(item => item.replace(path, ''))
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes)
  // Conversion and stitching parameters
  const renderRoutes = renderArr.map(item => {
    const exact = !routes.some(route => route !== item && getRelation(route, item) === 1)
    return {
      exact,
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`
    }
  })
  return renderRoutes
}

export function getPageQuery () {
  return parse(window.location.href.split('?')[1])
}

export function getQueryPath (path = '', query = {}) {
  const search = stringify(query)
  if (search.length) {
    return `${path}?${search}`
  }
  return path
}

const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/

export function isUrl (path) {
  return reg.test(path)
}

export function formatWan (val) {
  const v = val * 1
  if (!v || Number.isNaN(v)) return ''

  let result = val
  if (val > 10000) {
    result = Math.floor(val / 10000)
    result = (
      <span>
        {result}
        <span
          style={{
            position: 'relative',
            top: -2,
            fontSize: 14,
            fontStyle: 'normal',
            marginLeft: 2
          }}
        >
          万
        </span>
      </span>
    )
  }
  return result
}

export function log (param) {
  return console.dir(arguments)
}

export function getMutatedState (param) {
  return console.dir(arguments)
}


export function ReturnItemAmount(item, rateUnit) {

  if (!rateUnit) {
    return 0
  }
  let size = item.size
  let rate = item.rate
  let orderQty = item.orderQty
  if (size) {
    size = size.replace('CM', '')
    size = size.replace('FOOT', '')
    size = size.replace('KG', '')
    size = size.replace('OVEL', '')
    size = size.replace('SQR', '')
    size = size.replace('RO', '')
    size = size.trim()
    size = size.split('x')
  }


  if (size && size.length) {


    size = size.reduce((a, b) => a * b, 1)
    size = parseFloat(size).toFixed(2)
  }


    if (!size) size = 0;
    if (!rate) rate = 0;
    if (!orderQty) orderQty = 0;


    if (rateUnit == 'meter') {
        size = size / 10.764;

        let itemAmount = size * rate * orderQty;

        return parseFloat(itemAmount).toFixed(2);


    }
    if (rateUnit == 'foot') {


        let itemAmount = size * rate * orderQty;
        return parseFloat(itemAmount).toFixed(2);

    }

    return 0
}

export function ReturnChallanItemAmount(item, rateUnit) {

    if (item.type == "Raw Material") {
      return item.price ? item.price * item.qty : 0
    }

    if (!rateUnit) {
        return 0
    }
  

    let size = item.size
    let rate = item.price
    let orderQty = item.qty
    if (size) {
        size = size.replace('CM', '')
        size = size.replace('FOOT', '')
        size = size.replace('KG', '')
        size = size.replace('OVEL', '')
        size = size.replace('SQR', '')
        size = size.replace('RO', '')
        size = size.trim()
        size = size.split('x')
    }


    if (size && size.length) {


        size = size.reduce((a, b) => a * b, 1)
        size = parseFloat(size).toFixed(2)
    }


    if (!size) size = 0;
    if (!rate) rate = 0;
    if (!orderQty) orderQty = 0;


    if (rateUnit == 'meter') {
        size = size / 10.764;

        let totalAmount = size * rate * orderQty;

        return parseFloat(parseFloat(totalAmount).toFixed(2));


    }
    if (rateUnit == 'foot') {


        let totalAmount = size * rate * orderQty;
        return parseFloat(parseFloat(totalAmount).toFixed(2));

    }

    return 0
}
