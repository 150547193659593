import React, {useState, useRef, useEffect} from 'react'
import {Select, Spin} from 'antd'
import 'react-quill/dist/quill.snow.css'
import _ from 'lodash'

import {useSelector} from "react-redux";
import {SearchSupplierByName} from '../../containers/supplier/action'

const SearchSupplier = props => {

    const [optionArr, setOptions] = useState([])
    const [searching, setSearching] = useState(false)

    useEffect(() => {
        if (props.childFunc) {
            props.childFunc.current = test
        }
    }, [props.childFunc])

    useEffect(() => {
        
        if (props.childFuncSecond) {
            props.childFuncSecond.current = test
        }
    }, [props.childFuncSecond])
    const textInput = useRef({
        name: null,
        _id: null,
        vehicles: null,
        address: []
    })

    const test = () => {
        handleSearchFunc(null);

    }
    const handleSearchFunc = async (value) => {

        setSearching(true);

        let filter = {name: value};
        textInput.current.name = ''
        if (props.isMeghnaOffice) {
            filter.isMeghnaOffice = true;
        }

        let {success, data} = await SearchSupplierByName(filter)
        if (success) {
            setOptions(data);
        } else {
            setOptions([]);
        }
        setSearching(false);
    }

    useEffect(() => {
        handleSearchFunc(null)
    }, [])
    useEffect(() => {
        console.log(props)
        textInput.current.name = props.editValue
    }, [props.editValue])

    return (
        <div>
            {/* <Select>
                {
                    options.map((val, kkk) => {
                        return <Option value={val._id}>{val.name}</Option>
                    })
                }
            </Select>*/}
            <Select
                style={{width: '250px'}}
                showSearch
                allowClear

                value={textInput.current.name}
                // value={"textInput.current.name"}
                ref={textInput}
                filterOption={(input, option) => {

                    return (
                        option.props.children.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    )
                }}
                onClear={() => {
                    props.onSelect({
                        name: null,
                        _id: null,
                        vehicles: null,
                        address: [],
                        isMeghnaOffice: false
                    })
                }}
                onChange={(e) => {

                    let findIn = _.find(optionArr, (v) => {
                        return v._id == e;
                    })

                    if (findIn) {
                        props.onSelect({
                            name: findIn.name,
                            _id: findIn._id,
                            vehicles: findIn.vehicles,
                            address: findIn.address,
                            isMeghnaOffice: findIn.isMeghnaOffice
                        })
                        textInput.current.name = findIn.name;
                    } else {
                        props.onSelect({
                            name: null,
                            _id: null,
                            vehicles: null,
                            address: [],
                            isMeghnaOffice: null
                        })
                        textInput.current.name = null
                    }
                }}
            >

                {optionArr &&
                optionArr.map((mcc, ii) => {
                    return (
                        <Select.Option value={mcc._id} key={mcc._id}>
                            <small>{mcc.name}</small>
                        </Select.Option>
                    )
                })}
            </Select>

            {searching && <Spin size="small" style={{marginLeft: 10}}/>}
        </div>
    )
}
export default SearchSupplier
