import axios from '../../../serverAxios'
import { apiUrl, errorObj } from '../../../settings'
import { getToken } from '../../../request'

export const AddAction = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/attribute`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const ListAction = data => {
  return new Promise(async next => {
    await axios
      .get(`${apiUrl}/attribute`, { params: { ...data }, ...getToken() })
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const GetSanitizedAttribute = data => {
  return new Promise(async next => {
    await axios
      .get(`${apiUrl}/getSanitizedAttribute`, {
        params: { ...data },
        ...getToken()
      })
      .then(x => {
       
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const UpdateAttributeAction = data => {
  return new Promise(async next => {
    await axios
      .put(`${apiUrl}/attribute`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const DeleteAttribute = data => {
  return new Promise(async next => {
    await axios
      .delete(`${apiUrl}/attribute/` + data._id, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const verifiedChallanAction = async params => {
  return new Promise(async next => {
    await axios
      .put(`${apiUrl}/verified/challan`, params, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const billingCompleteAction = async params => {
  return new Promise(async next => {
    await axios
      .put(`${apiUrl}/challan/billingComplete`, params, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
