const initialState = {
    challanItems: [],
    weightClassificationItems: []
}

export default (state = initialState, action) => {
    switch (action.type) {

        case 'SET_CHALLAN_ITEMS':
            return {
                ...state,
                challanItems: action.data
            }
        case 'SET_CHALLAN_SEARCH_ITEMS':
            return {
                ...state,
                challanSearchItems: action.data
            }
        case 'SET_WEIGHT_CLASSIFICATION':
            return {
                ...state,
                weightClassificationItems: action.data
            }

        default:
            return state
    }
}
