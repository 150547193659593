import React, {useEffect, useState,useRef } from 'react'
import {Select, Spin} from 'antd'
import 'react-quill/dist/quill.snow.css'
import {SearchProductByName} from '../../containers/variants/action'
import {searchProductToReport} from '../../containers/supplier/action'
import _ from 'lodash';

const SearchProduct = props => {
    const [optionArr, setOptions] = useState([])
    const [searching, setSearching] = useState(false)
    const textInput = useRef({
        name: null,
        _id: null,
        vehicles: null,
        address: []
      })

    useEffect(() => {
        handleSearch(null)
    }, [])
    useEffect(() => {
       
        textInput.current.name = props.editValue
      }, [props.editValue])
    
    const handleSearch = async value => {
        // setOptions([])
        textInput.current.name = ''
        setSearching(true)
        setSearching(true)
                let {success, data} = await SearchProductByName({name: value})
               if (success) {
                   setOptions(
                    data
                    )
                } else {
                    setOptions([])
               }
    //   else{
    //     let {success, data} = await SearchProductByName({name: value})
    //     if (success) {
    //         setOptions(
    //             data
    //         )
    //     } else {
    //         setOptions([])
    //     }
    //   }
        setSearching(false)
    }
    return (
        <div>
            {/* <AutoComplete
          optionLabelProp="name"
          dropdownMatchSelectWidth={'100%'}
          dropdownClassName="certain-category-search-dropdown"
          allowClear={true}
          options={options}
          value={props.value}
          defaultOpen={true}
          style={{width: 250}}
          onClear={e => {
            props.onSelect(null)
          }}
          onSelect={(e, option) => {
          props.onSelect({ _id: option.key, name: option.value })
        }}
          onSearch={handleSearch}
          placeholder="Search Product"
      />*/}
            <Select
                style={{width: '250px'}}
                showSearch
                size={props.size ? props.size : 'medium'}
                allowClear
                value={textInput.current.name}
            ref={textInput}
                filterOption={(input, option) => {
                    let arr = _.compact(option.props.children.props.children)
                    arr = arr.toString()
                    return (

                        arr.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    )
                }}
                onClear={() => {
                    props.onSelect({
                        name: null,
                        _id: null,

                    })
                }}
                onChange={(e) => {

                    let findIn = _.find(optionArr, (v) => {
                        return v._id == e;
                    })

                    if (findIn) {
                        if (props.showCode) {
                            props.onSelect({
                                name: findIn.code,
                                _id: findIn._id,
                            })
                            textInput.current.name = findIn.code;
                        } else {
                            props.onSelect({
                                name: `${findIn.name} ${findIn.code ? `(${findIn.code})` : ''}`,
                                _id: findIn._id,
                            })
                            textInput.current.name = null
                        }

                    } else {
                        props.onSelect({
                            name: null,
                            _id: null,

                        })
                    }
                }}
            >

                {optionArr &&
                optionArr.map((mcc, ii) => {
                    return (
                        <Select.Option value={mcc._id} key={mcc._id}>
                            {
                                props.showCode ? <small>{mcc.code}</small> :
                                    <small>{mcc.name} {mcc.code ? `(${mcc.code})` : ''}</small>
                            }

                        </Select.Option>
                    )
                })}
            </Select>

            {searching && <Spin size="small" style={{marginLeft: 10}}/>}
        </div>
    )
}
export default SearchProduct
