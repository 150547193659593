import axios from '../../../serverAxios'
import {apiUrl, errorObj} from '../../../settings'
import {getToken} from '../../../request'

export const AddAction = data => {
    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/rejection`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const ListAction = data => {
    return new Promise(async next => {
        await axios
            .get(`${apiUrl}/rejection`, {params: {...data}, ...getToken()})
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const UpdateAction = data => {
    return new Promise(async next => {
        await axios
            .put(`${apiUrl}/rejection`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const GetAAction = id => {
    return new Promise(async next => {
        await axios
            .get(`${apiUrl}/rejection/${id}`, {params: {}, ...getToken()})
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const ExportRejectionsAction = data => {
    return new Promise(async next => {
        await axios
            .get(`${apiUrl}/rejection/exportRejection`, {
                params: {...data},
                ...getToken()
            })
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const PrintRejectionAction = id => {
    return new Promise(async next => {
        await axios
            .get(`${apiUrl}/printRejection/${id}`, {
                ...getToken()
            })
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const DeleteChallanId = data => {
    //console.log(data,"jhjhjhj")
    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/rejection/deleteProduct`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
             })
     })
    }
export const SearchChallanItemsAction = data => {
    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/challan/getReceivedItems`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}

export const DeleteRejection = data => {
    return new Promise(async next => {
        await axios
            .delete(`${apiUrl}/rejection/${data._id}`,getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
             })
     })
}