import React, {useEffect, useRef, useState} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils4'
import {
    Card,
    Button,
    Tooltip,
    Row,
    Col,
    DatePicker,
    notification,
    Drawer,
    Table,
    Tag,
    Popconfirm
} from 'antd'
import {
    ListAction,
    ExportRejectionsAction,
    PrintRejectionAction,
    DeleteRejection
} from '../action'
import moment from 'moment'
import {useDispatch} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'
import styles from './styles.less'
import {
    PlusOutlined,
    UserOutlined,
    PhoneOutlined,
    MailOutlined,
    DownloadOutlined,
    EditOutlined,
    ProfileOutlined,
    DeleteOutlined
} from '@ant-design/icons'
import SearchSupplier from '../../../components/search/supplier'
import {apiUrl, ResizableTitle} from '../../../settings'
import {dateFormat} from "../../../request";

const {RangePicker} = DatePicker

const AllRejections = () => {
    const dispatch = useDispatch()
    const [total, setTotal] = useState(0)
    let [dateFilter, setDateFilter] = useState()
    const [fromSupplierId, setFromSupplierId] = useState()
    const [toSupplierId, setToSupplierId] = useState()
    const [filter, setFilter] = useState()
    const [visible, setVisible] = useState(false)
    const [editLogs, setEditRejectionData] = useState([])
    const [columns, setColumns] = useState([
        // {
        //     title: 'Challan Id',
        //     key: 'challanId',
        //     searchTextName: 'challanId',
        //     fixed: 'left'
        // },

        {
            title: 'Id',
            key: 'rejectionNumber',
            searchTextName: 'rejectionNumber',
            width: 50
        },
        {title: 'From', key: 'fromSupplierName', width: 200},
        {title: 'To', key: 'toSupplierName', width: 200},
        {
            title: 'Date',
            key: 'rejectionDate',
            width: 90,
            render: (val, record) => {
                return moment(val).format(dateFormat)
            }
        },
        {
            title: 'Sender Address',
            key: 'fromAddress',
            width: 150,
            render: (val, record) => {
                if (val) {
                    return (
                        <div className={styles.fromShow}>

                            {val.address ? val.address.substring(0, 15) + '...' : ''}

                        </div>
                    )
                } else {
                    return null
                }
            },
            searchTextName: 'FromAddress'
        },
        {
            title: 'Receiver Address',
            key: 'toAddress',
            width: 150,
            render: (val, record) => {
                if (val) {
                    return (
                        <div className={styles.toShow}>

                            {val.address ? val.address.substring(0, 15) + '...' : ''}

                        </div>
                    )
                } else {
                    return null
                }
            },
            searchTextName: 'ToAddress'
        },
        // {
        //     title: 'Vehicle',
        //     key: 'vehicleName',
        //     render: (val, record) => {
        //         if (val) {
        //             return (
        //                 <div>
        //                     {val}
        //                     <small> {record.vehicleNumber}</small>
        //                 </div>
        //             )
        //         } else {
        //             return ''
        //         }
        //     },
        //     searchTextName: 'Vehicle'
        // },
        {
            title: 'Qty',
            key: 'totalQty',
            sorter: true,
            width: 50
        },
        // {title: 'Reference', key: 'rejectionRef', width: 150,},

        // {
        //     title: 'Weighing Slip',
        //     key: 'weighingSlip',
        //     width: 160,
        //     render: (val, record) => {
        //         if (record.weighingSlip) {
        //             return (
        //                 <div>
        //                     <Button
        //                         onClick={() => {
        //                             window.open(apiUrl + '/' + record.weighingSlip[0].url)
        //                         }}
        //                         type="dashed"
        //                         size={'small'}
        //                         icon={<DownloadOutlined/>}>
        //                         Download
        //                     </Button>
        //                 </div>
        //             )
        //         } else {
        //             return null
        //         }
        //     }
        // },
        // {
        //     title: 'Rejection Photo',
        //     key: 'rejectionPhoto',
        //     width: 160,
        //     render: (val, record) => {
        //         if (record.rejectionPhoto) {
        //             return (
        //                 <div>
        //                     <Button
        //                         size={'small'}
        //                         onClick={() => {
        //                             window.open(apiUrl + '/' + record.rejectionPhoto[0].url)
        //                         }}
        //                         type="dashed"
        //                         icon={<DownloadOutlined/>}>
        //                         Download
        //                     </Button>
        //                 </div>
        //             )
        //         } else {
        //             return null
        //         }
        //     }
        // },
        {
            title: 'Updated',
            key: 'updatedAt',
            width: 90,
            render: (item, record) => {
                return (
                    <Tooltip title={moment(item).format('lll')}>
                        {moment(item).fromNow()}

                    </Tooltip>
                )
            },
            sorter: true
        },
        {
            key: 'actions',
            title: 'Actions',
            fixed: 'right',
            width: 200,

            render: (text, record) => {
                return (
                    <React.Fragment>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Tooltip title="Edit Rejection">
                                <Button
                                    shape="circle"
                                    style={{marginRight: 6}}
                                    size="small"
                                    onClick={() => {
                                        dispatch(
                                            getUrlPushWrapper('reducer.edit', {id: record._id})
                                        )
                                    }}
                                    icon={<EditOutlined/>}
                                />
                            </Tooltip>
                            <Tooltip title="Download Rejection">
                                <Button
                                    shape="circle"
                                    style={{marginRight: 6}}
                                    size="small"
                                    loading={
                                        loadingDownload.id == record._id && loadingDownload.loading
                                    }
                                    onClick={async () => {

                                        setLoadingDownload({id: record._id, loading: true})
                                        const data = await PrintRejectionAction(record._id)

                                        if (data.success) {
                                            window.open(data.url)
                                        }
                                        setLoadingDownload({id: null, loading: false})

                                        dispatch({type: 'HIDE_BTN_LOADING'})
                                    }}
                                    icon={<DownloadOutlined/>}
                                />
                            </Tooltip>
                            {/* <Tooltip title="Rejection Logs">
                                <Button
                                    shape="circle"
                                    style={{marginRight: 6}}
                                    size="small"
                                    loading={
                                        loadingDownload.id == record._id && loadingDownload.loading
                                    }
                                    onClick={async () => {
                                        setEditRejectionData(record.editLogs)
                                        setVisible(true)
                                    }}
                                    icon={<ProfileOutlined/>}
                                />
                            </Tooltip>*/}
                            <Popconfirm 
                            title={"Are You Sure To Delete Rejection"}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={async () => {
                              
                                let {success, message, error} = await DeleteRejection({_id: record._id})
                                if (success) {
                                   notification.success({message: "Rejection Deleted Successfuly"});
                                   table.current.reload();
                                }
                                if (error) {
                                    notification.error({message: message});
                                }
                            }}
                            >
                             <Tooltip title="Delete Rejection">
                                <Button
                                    shape="circle"
                                    style={{marginRight: 6}}
                                    size="small"
                                    type={"danger"}
                                    icon={<DeleteOutlined/>}
                                />
                            </Tooltip>
                            </Popconfirm>

                        </div>
                    </React.Fragment>
                )
            }
        }
    ])

    const [loadingDownload, setLoadingDownload] = useState({
        id: null,
        loading: false
    })

    const [exportLoader, setExportLoader] = useState(false)
    const table = useRef(null)

    const apiRequest = params => {
        return new Promise(async resolve => {
            if (!params.sortField || !params.sortOrder) {
                params.sortField = 'rejectionNumber'
                params.sortOrder = 'descend'
            }
            if (dateFilter && dateFilter.length) {
                params.rejectionDate = {
                    $gte: dateFilter[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                    $lt: dateFilter[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
                }
            }
            if (toSupplierId) {
                params.toSupplierId = toSupplierId
            }
            if (fromSupplierId) {
                params.fromSupplierId = fromSupplierId
            }
            let data = await ListAction({
                ...params,
                regExFilters: ['name', 'unitName', 'toSupplierName', 'fromSupplierName']
            })
            if (data && !data.error) {
                setTotal(data.total)
            }
            return resolve(data)
        })
    }
    useEffect(
        () => {
            table.current.reload()
        },
        [filter]
    )
    const exportData = async () => {
        setExportLoader(true)
        let params = {}
        params.sortField = 'rejectionNumber'
        params.sortOrder = 'descend'
        if (dateFilter && dateFilter.length) {
            params.rejectionDate = {
                $gte: dateFilter[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                $lt: dateFilter[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
            }
        }
        if (toSupplierId) {
            params.toSupplierId = toSupplierId
        }
        if (fromSupplierId) {
            params.fromSupplierId = fromSupplierId
        }
        let data = await ExportRejectionsAction({
            ...params,
            // export: true,
            regExFilters: ['name', 'unitName', 'toSupplierName', 'fromSupplierName']
        })
        setExportLoader(false)
        if (!data.error) {
            window.open(data.url)
        } else {
            return notification.error({message: 'Error in Export File'})
        }
    }

    const editRejectionColumns = [
        // {
        //   title: 'S.No',
        //   dataIndex: 'sno',
        //   key: 'sno',
        //   width: 70,
        //   render: (value, item, index) => {
        //     return index + 1
        //   }
        // },
        {
            title: 'User Name',
            dataIndex: 'userName',
            key: 'userName',
            width: 70
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 100,
            render: (val, record) => {
                return val && <Tag>{moment(val).format('lll')}</Tag>
            }
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
            width: 100
        }
    ]
    const handleResize = (index) => (_, {size}) => {
        const newColumnss = [...columns];
        newColumnss[index] = {...newColumnss[index], width: size.width};
        setColumns(newColumnss);
    };

    const mergeColumns = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width || 180,
            onResize: handleResize(index),
        }),
    }));


    return (
        <PageHeaderWrapper title={`All Rejections (${total})`}>
            <Card bordered={true}>
                <Row>
                    <Col span={6}>
                        <h4 style={{marginRight: '10px'}}>Select Rejection Date</h4>
                        <RangePicker
                            id={'rangePicker'}
                            style={{marginRight: '10px'}}
                            onChange={date => {
                                setDateFilter(date)
                                setFilter(Date.now())
                            }}
                        />
                    </Col>
                    <Col span={6}>
                        <h4>Search From Supplier</h4>
                        <SearchSupplier
                            onSelect={e => {
                                setFromSupplierId(e && e._id)
                                setFilter(Date.now())
                            }}
                        />
                    </Col>
                    <Col span={6}>
                        <h4>Search To Supplier</h4>
                        <SearchSupplier
                            onSelect={e => {
                                setToSupplierId(e && e._id)
                                setFilter(Date.now())
                            }}
                        />
                    </Col>
                    <Col span={6} style={{textAlign: 'right'}}>
                        <h4>
                            <br/>
                        </h4>
                        <Button
                            type="default"
                            style={{marginRight: 10}}
                            onClick={() => {
                                exportData()
                            }}
                            loading={exportLoader}>
                            Export
                        </Button>
                        <Button
                            type="primary"
                            icon={<PlusOutlined/>}
                            onClick={() => {
                                dispatch(getUrlPushWrapper('reducer.add'))
                            }}>
                            Add New Rejection
                        </Button>
                    </Col>
                </Row>

                <TableComp
                    ref={table}
                    columns={mergeColumns}
                    extraProps={{
                        scroll: {x: 800},
                        components: {
                            header: {
                                cell: ResizableTitle,
                            },
                        }
                    }}
                    apiRequest={apiRequest}
                />
            </Card>
            <Drawer
                width={1000}
                title={'Edit Rejection Logs'}
                placement="right"
                onClose={() => setVisible(false)}
                visible={visible}>
                <Card>
                    <Table
                        bordered={true}
                        rowKey={x => x._id}
                        columns={editRejectionColumns}
                        dataSource={editLogs || []}
                        // pagination={false}
                    />
                </Card>
            </Drawer>
        </PageHeaderWrapper>
    )
}

export default AllRejections
