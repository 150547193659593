import axios from '../../../serverAxios'
import {apiUrl, errorObj} from '../../../settings'
import {getToken} from '../../../request'


export const addSupplierOrderAction = async (params) => {
    try {
        const {data} = await axios.post(`${apiUrl}/supplierOrder`, params, getToken())
        return data
    } catch (e) {
        console.log(e);
    }
}

export const ListAction = async (params) => {
    try {
        const {data} = await axios.get(`${apiUrl}/supplierOrder`, {params: {...params}, ...getToken()})

        return data;
    } catch (err) {
        console.log(err);
    }
}

export const GetSupplierOrderAction = (id) => {
    return new Promise(async next => {
        await axios
            .get(`${apiUrl}/supplierOrder/${id}`, {params: {}, ...getToken()})
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const PrintSupplierOrderAction = async (params) => {

    try {

        const data = await axios.post(`${apiUrl}/printSupplierOrder`, params, getToken())
        return data
    } catch (e) {
        console.log(e);
    }
}
export const PrintSupplierOrder = id => {
    return new Promise(async next => {
        await axios
            .get(`${apiUrl}/printSupplyOrder/${id}`, {
                ...getToken()
            })
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const UpdateSupplierOrderAction = data => {
    return new Promise(async next => {
        await axios
            .put(`${apiUrl}/supplierOrder`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const DeleteChallanId = data => {

    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/supplierOrder/deleteProduct`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const NextChallanIdAction = data => {
    return new Promise(async next => {
        await axios
            .get(`${apiUrl}/nextSupplierOrderId`, {params: {...data}, ...getToken()})
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const GetRawWeightClassification = data => {

    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/getRawWeightClassification`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const DeleteSupplierOrder = data => {
    return new Promise(async next => {
        await axios
          .delete(`${apiUrl}/supplierOrder/` + data._id, getToken())
          .then(x => {
            return next(x.data)
          })
          .catch(error => {
            return next({ ...errorObj, message: error })
          })
    })
}

// ========= challan pending jobs action  =============

export const AddVariantAction = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/productVariant`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const UpdateProductVariationAction = data => {
  return new Promise(async next => {
    await axios
      .put(`${apiUrl}/productVariant`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const ListProductVariantsAction = data => {
  return new Promise(async next => {
    await axios
      .get(`${apiUrl}/productVariant`, { params: { ...data }, ...getToken() })
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const SearchVariantAction = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/searchVariant`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const SearchVariantForPOAction = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/searchVariantForPO`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const ListVariantAction = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/listVariant`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const SearchProductByName = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/searchProductByName`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const VariantChallanLedgarAction = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/variantChallanLedgar`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const GetProductVariantListAction = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/getProductVariantList`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const ProductLedgerAction = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/productLedger`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const ExportVariantChallanReportAction = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/variantChallanLedger/exportReport`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const DeleteVariant = data => {
  return new Promise(async next => {
    await axios
      .delete(`${apiUrl}/variant/` + data._id, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const VariationDispatchDetail = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/variant/dispatch/detail`, data, getToken())
      .then(x => {
        return next(x.data)
      })

      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const GetPendingJobsItemsAction = data => {
    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/supplierOrder/getPendingJobsItems`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}


