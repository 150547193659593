import React, {useEffect, useState} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Button,
    Input,
    DatePicker,

    Card,

    Select,
    Spin,
    Row,
    Col,
    Popconfirm,
    Typography
} from 'antd'
import 'react-quill/dist/quill.snow.css'
import {
    PrinterOutlined,
    SaveOutlined,
    SearchOutlined
} from '@ant-design/icons'
import {notification} from 'antd/lib/index'

import _ from 'lodash'
import moment from 'moment'
import styles from '../../challan/view/styles.less'

import {GetSanitizedAttribute} from '../../attribute/action'
import {TableComp} from 'sz-react-utils4'
import {dateFormat} from '../../../request'
import SearchSupplier from '../../../components/search/supplier'
import SearchPo from '../../../components/search/po'
import {addSupplierOrderAction, GetSupplierOrderAction, PrintSupplierOrder} from '../actions'
import {NextChallanIdAction} from "../actions";
import SearchProduct from "../../variants/view/search";

let proArr = []

const {TextArea} = Input
const purposeArr = [
    {name: 'Job Work', value: 'jobWork'},
    {name: 'Purchase Order', value: 'purchaseOrder'}
]
const EditRawOrder = props => {
    const [spinLoader, setSpinLoader] = useState(true)
    const [showSearch, setSearch] = useState(false)
    const [loader, setLoader] = useState(false)
    const [id, setId] = useState(null)
    let [arr, setArr] = useState([])

    const [obj, setObj] = useState({
        supplierId: null,
        supplierName: '',
        poDocId: null,
        date: moment(),
        purchaseOrderNumber: null,
        totalApproxWeight: 0,
        purpose: null
    })

    let [cols, setCols] = useState([
        {
            title: 'Product',
            key: 'productName',
            dataKey: 'productName',

            fixed: 'left',
            onFilter: (value, record) => record.productName.indexOf(value) === 0
        },
        {
            title: 'Colour',
            key: 'colour',
            dataKey: 'colour',

        },

    ])

    const {Option} = Select

    useEffect(() => {

        fetchInfo()

        fetchAttributes()
    }, [])


    const fetchInfo = async () => {
        let searchParams = new URLSearchParams(window.location.search)
        let id = searchParams.get('id')

        if (id) {
            setId(id)

            let {data, error} = await GetSupplierOrderAction(id)


            if (!error) {
                setObj({
                    ...obj,
                    date: moment(data.date),
                    dueDate: moment(data.dueDate),
                    notes: data.notes,
                    totalQty: data.totalQty,
                    supplierName: data.supplierName,
                    supplierId: data.supplierId,
                    poDocId: data.poDocId,
                    purchaseOrderNumber: data.purchaseOrderNumber,
                    orderNumber: data.orderNumber,
                    purpose: data.purpose,
                    _id: id
                })

                setArr(data.arr);

            }
        }
    }

    useEffect(
        () => {
            proArr = arr
        },
        [arr]
    )

    useEffect(
        () => {
            calTotalQty()
        },
        [arr]
    )


    const fetchAttributes = async () => {

        let {success, data} = await GetSanitizedAttribute({showInList: true})

        data = _.filter(data, (v) => v.key == 'weight');

        if ((success, data)) {

            cols.push({

                title: 'Order Qty',
                key: 'orderQty',

                render: (val, record) => {
                    return (
                        <Row gutter={{md: 10}}>
                            <Col><Input
                                value={val}
                                className={styles.inputNumber}
                                type={'number'}
                                min={1}
                                onChange={e => {
                                    record.orderQty = e.target.value;
                                    record.type = 'Product';

                                    let tempp = _.cloneDeep(proArr)
                                    let output = _.find(tempp, v => {
                                        return record._id == v._id
                                    })
                                    if (output) {
                                        output.orderQty = Math.abs(e.target.value)
                                    } else {
                                        tempp.push(record);
                                    }


                                    setArr(tempp)
                                }}

                            /></Col>
                            <Col>
                                <small> {record.unitName}</small>
                            </Col>
                        </Row>

                    )
                }
            })
            cols.push({
                title: 'Rate',
                key: 'rate',
                width: 100,

                render: (val, record) => {
                    return (
                        <Input
                            value={val}
                            type={'number'}
                            min={1}
                            className={styles.inputNumber}
                            onChange={e => {
                                record.rate = e.target.value
                                let tempp = _.cloneDeep(proArr)
                                let output = _.find(tempp, v => {
                                    return record._id == v._id
                                })

                                if (output) {
                                    output.rate = Math.abs(e.target.value)
                                    setArr(tempp)
                                }
                            }}
                        />
                    )
                }
            })
            /*_.each(data, v => {
                delete v.filters
                delete v.searchTextName
                cols.push({
                    ...v
                })
            })*/
            /*  cols.push({
                  title: 'Approx Weight',
                  key: 'approxWeight',

                  render: (val, record) => {
                      return <div>{returnApproxWeight(record)}</div>
                  }
              })*/
            cols.push({
                title: 'Remove',
                key: 'key',
                fixed: 'right',

                width: 100,
                render: (val, record) => {
                    return (
                        <div>
                            <Popconfirm
                                title="Are you sure to remove?"
                                onConfirm={() => {
                                    proArr = _.reject(proArr, function (e) {
                                        return record._id == e._id
                                    })
                                    setArr(proArr)
                                }}
                                onCancel={() => {
                                }}
                                okText="Yes"
                                cancelText="No">
                                <a>Remove</a>
                            </Popconfirm>
                        </div>
                    )
                }
            })
            setCols(cols)


            setSpinLoader(false)
        }
    }


    const calTotalQty = () => {
        let rQ = 0
        let pQ = 0
        let aW = 0
        _.each(arr, v => {
            pQ += v.orderQty ? parseFloat(v.orderQty) : 0

            if (v.orderQty && v.weight) {
                aW += parseInt(parseFloat(v.orderQty) * parseFloat(v.weight))
            }
        })

        setObj({
            ...obj,
            totalQty: rQ + pQ,
            totalApproxWeight: aW
        })
    }

    const saveOnly = async (supplierPrint) => {

        if (checkValidation() == 'error') {
            return
        }

        notification.success({
            message: 'Supplier Order Saved Successfully'
        })
        return;


        setLoader(true)

        obj.orderType = 'Raw Order';

        let x = await addSupplierOrderAction({...obj, arr: arr})
        setLoader(false)
        if (!x.error) {
            notification.success({
                message: 'Supplier Order Saved Successfully'
            })
            if (supplierPrint) {
                const data = await PrintSupplierOrder(x && x.data && x.data._id)
                if (data.success) {
                    window.open(data.url)
                }
            }
            setTimeout(() => {
                window.location.reload(true)
            }, 500)

            return
        } else {
            notification.error({
                message: 'Error Saving',
                description: x.message
            })
        }
    }

    const checkValidation = () => {
        if (!obj.supplierId) {
            notification.error({message: 'Choose a supplier'})
            return 'error'
        }
        if (!obj.purchaseOrderNumber) {
            notification.error({message: 'Choose a PO'})
            return 'error'
        }
        if (!obj.purpose) {
            notification.error({message: 'Choose Purpose'})
            return 'error'
        }

        if (!arr || !arr.length) {
            notification.error({message: 'Choose Products'})
            return 'error'
        }

        let findIn = _.find(arr, v => {
            return (isNaN(v.orderQty) || parseInt(v.orderQty) <= 0 || !v.orderQty)
        })

        if (findIn) {
            notification.error({
                message: 'Required',
                description: `Enter quantity for ${findIn.productName}`
            })
            return 'error'
        }


        return
    }


    return (
        <PageHeaderWrapper title={`${id ? 'Edit' : 'Add'} Raw Material Order`}>
            <Spin spinning={spinLoader}>

                <Card>
                    <Row gutter={{md: 10}} style={{marginBottom: 10}}>
                        <Col span={4}>
                            <div><b>Order Date</b></div>

                            <DatePicker
                                format={dateFormat}
                                value={obj.date}
                                onChange={e => {
                                    setObj({
                                        ...obj,
                                        date: e
                                    })
                                }}
                            />
                        </Col>
                        <Col span={4}>
                            <div><b> Supplier</b></div>
                            <div>{obj.supplierName}</div>

                        </Col>
                        <Col span={4}>
                            <div><b> Purpose</b><br></br></div>
                            <div>{obj.purpose}</div>
                        </Col>
                        <Col span={4}>
                            <div><b>PO</b></div>
                            <div>{obj.purchaseOrderNumber}</div>
                        </Col>
                        <Col span={4}>
                            <div><b>Order NO</b></div>
                            <div>{obj.orderNumber}</div>
                        </Col>
                        <Col span={4}
                        >
                            <div><b>Due Date</b></div>

                            <DatePicker
                                onChange={(date, dateString) => {
                                    setObj({
                                        ...obj,
                                        dueDate: dateString
                                    })
                                }}
                            />
                        </Col>

                    </Row>

                    <Row>
                        <Col span={4}>
                            <br/>
                            <Button onClick={() => setSearch(true)}>
                                <SearchOutlined/> Search Raw Material
                            </Button>
                        </Col>
                    </Row>


                </Card>

                {/*    {JSON.stringify(arr)}*/}

                <Card title={`Collections (${arr.length})`}>
                    {!spinLoader ? (
                        <div>
                            <TableComp
                                dataSource={arr}
                                extraProps={{scroll: {x: true}}}
                                loading={loader}

                                pagination={{
                                    defaultPageSize: 500,
                                    pageSizeOptions: ['100', '250', '500', '1000'],
                                    showSizeChanger: true
                                }}
                                columns={cols}
                            />
                        </div>
                    ) : null}
                </Card>
                <Card>
                    <Row className={styles.noteArea}>
                        <Col>
                            <div>
                                <b>Note if any</b>
                            </div>

                            <TextArea
                                style={{width: 300}}
                                onChange={e => {
                                    setObj({
                                        ...obj,
                                        notes: e.target.value
                                    })
                                }}
                                rows={2}
                            />
                        </Col>

                        <Col className={styles.totalQty}>
                            <b>Total Weight : {obj.totalQty} </b>
                        </Col>
                        {/*   <Col className={styles.totalQty}>
                            <b>Total Approximate Weight : {obj.totalApproxWeight} KG </b>
                        </Col>*/}
                    </Row>
                </Card>
                {/* <Card>
                    <Row className={styles.btnctr}>


                        <Col style={{marginRight: 10}}>
                                <Button disabled={loader} onClick={() => {
                                    saveOnly(false)
                                }} type={'primary'}><SaveOutlined/> Save Only</Button>
                        </Col>
                        <Col>
                            <Button disabled={loader} onClick={() => {
                                saveOnly(true)
                            }} type={'primary'}><PrinterOutlined/> Save & Print</Button>
                        </Col>
                    </Row>
                </Card>*/}
            </Spin>


            {showSearch ? (
                <SearchProduct
                    includeRawMaterial={true}
                    onSeleced={tempArr => {

                        let testArr = _.cloneDeep(arr)
                        _.each(tempArr, (val, kk) => {
                            let findIn = _.find(testArr, vv => {
                                return (vv._id == val._id || vv.variantId == val._id)
                            })
                            if (!findIn) {
                                testArr.push(val)
                            } else {
                                findIn.orderQty = val.orderQty;
                            }
                        })
                        setArr(testArr)

                    }}
                    onClose={() => {
                        setSearch(false)
                    }}
                />
            ) : null}
        </PageHeaderWrapper>
    )
}
export default EditRawOrder
