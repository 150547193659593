import React, {lazy} from 'react'
import {push} from 'connected-react-router'
import _ from 'lodash'
import Path from 'path-parser'
import {
    DashboardOutlined,
    UserAddOutlined,
    AppstoreOutlined,
    SettingOutlined
} from '@ant-design/icons'
import AddPieceOrder from './containers/supplierOrder/views/addPiece'
import AddRawOrder from './containers/supplierOrder/views/addRaw'
import AllSupplierOrders from './containers/supplierOrder/views/all'
import EditRawOrder from './containers/supplierOrder/views/editRaw'
import AddRejection from './containers/rejection/view/add'
import EditRejection from './containers/rejection/view/edit'
import AllRejections from './containers/rejection/view/all'

const Dashboard = lazy(() => import('./containers/dashboard'))
const Undercons = lazy(() => import('./containers/undercons'))
const AllUsers = lazy(() => import('./containers/users/all'))
const AddUsers = lazy(() => import('./containers/users/add'))
const ChangePassword = lazy(() =>
    import('./containers/users/add/changePassword')
)

const AddUnit = lazy(() => import('./containers/unit/view/add'))
const AddJobType = lazy(() => import('./containers/jobType/view/add'))
const AllUnit = lazy(() => import('./containers/unit/view/all'))
const AllJobType = lazy(() => import('./containers/jobType/view/all'))

const AddAttribute = lazy(() => import('./containers/attribute/view/add'))
const AllAttribute = lazy(() => import('./containers/attribute/view/all'))

const AddRaw = lazy(() => import('./containers/raw/view/add'))
const AllRaw = lazy(() => import('./containers/raw/view/all'))
const RawSettings = lazy(() => import('./containers/raw/view/rawSettings'))

const RawReport = lazy(() => import('./containers/raw/view/report'))
const RawLedger = lazy(() => import('./containers/raw/view/ledger'))

const ProductOpeningStock = lazy(() =>
    import('./containers/openingStock/view/product')
)
const RawOpeningStock = lazy(() =>
    import('./containers/openingStock/view/rawStock')
)
const ListOpeningStock = lazy(() =>
    import('./containers/openingStock/view/list')
)

const AddProduct = lazy(() => import('./containers/product/view/add'))
const AllProduct = lazy(() => import('./containers/product/view/all'))

const AddVariant = lazy(() => import('./containers/variants/view/add'))
const AllVariants = lazy(() => import('./containers/variants/view/all'))
const ChallanProductReport = lazy(() =>
    import('./containers/variants/view/report')
)
const ProductLedger = lazy(() => import('./containers/variants/view/ledger'))

const AddChallan = lazy(() => import('./containers/challan/view/add'))
const AllChallan = lazy(() => import('./containers/challan/view/all'))
const EditChallan = lazy(() => import('./containers/challan/view/edit'))

const AddOutward = lazy(() => import('./containers/customerOutward/view/add'))
const AllOutward = lazy(() => import('./containers/customerOutward/view/all'))
const EditOutward = lazy(() => import('./containers/customerOutward/view/edit'))
/*const OutwardProductReport = lazy(() =>
  import('./containers/customerOutward/view/report')
)*/
const dispatchReport = lazy(() => import("./containers/customerOutward/view/dispatchReport"))

const AddSupplier = lazy(() => import('./containers/supplier/view/add'))
const AllSupplier = lazy(() => import('./containers/supplier/view/all'))

const AddCustomer = lazy(() => import('./containers/customer/view/add'))
const AllCustomer = lazy(() => import('./containers/customer/view/all'))

const AddPurchaseOrder = lazy(() =>
    import('./containers/purchaseOrder/view/add')
)
const AllPurchaseOrder = lazy(() =>
    import('./containers/purchaseOrder/view/all')
)
const EditPurchaseOrder = lazy(() =>
    import('./containers/purchaseOrder/view/edit')
)
const EditSupplierOrder = lazy(() =>
    import('./containers/supplierOrder/views/edit')
)
const SupplierReport = lazy(() =>
    import('./containers/challan/view/report')
)
const PoReport = lazy(() =>
    import('./containers/challan/view/poReport')
)
const SupplierPoReport = lazy(() =>
    import('./containers/challan/view/supplierPOReport')
)
const ChallanWeightList = lazy(() =>
    import('./containers/challan/view/weightList')
)

const PurchaseOrderDetails = lazy(() =>
    import('./containers/purchaseOrder/view/poDetails')
)


const AddProjection = lazy(() =>
    import('./containers/projection/view/add')
)
const EditProjection = lazy(() =>
    import('./containers/projection/view/edit')
)
const AllProjection = lazy(() =>
    import('./containers/projection/view/all')
)
const AllDispatch = lazy(() =>
    import("./containers/customerOutward/view/dispatchList")
)

const PendingJobs = lazy(() =>
  import('./containers/supplierOrder/views/pendingJobs')
)
const menu = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        title: 'Dashboard',
        icon: <DashboardOutlined/>,
        key: 'dashboard',
        homepage: true,
        component: Dashboard,
        authority: ['admin', 'user']
    },
    {
        path: '/settings',
        name: 'Settings',
        icon: <SettingOutlined/>,
        key: 'settings',
        component: Undercons,
        authority: ['admin', 'user'],
        children: [
            {
                path: '/settings/users/add',
                name: 'Add User',
                title: 'Add User',
                component: AddUsers,
                key: 'addUser',
                dontShowOnMenu: true
            },
            {
                path: '/settings/users/edit',
                name: 'Edit User',
                title: 'Edit User',
                dontShowOnMenu: true,
                key: 'editUser',
                component: AddUsers
            },
            {
                path: '/settings/users/all',
                name: 'All Users',
                title: 'All Users',
                component: AllUsers
            },
            {
                path: '/settings/users/changePassword',
                name: 'Change Password',
                title: 'Change Password',
                component: ChangePassword,
                key: 'changePassword',
                dontShowOnMenu: true
            },
            {
                path: '/settings/units/add',
                name: 'Add Unit',
                title: 'Add Unit',
                dontShowOnMenu: true,
                key: 'addUnit',
                component: AddUnit
            },
            {
                path: '/settings/jobType/add',
                name: 'Add Job Type',
                title: 'Add Job Type',
                dontShowOnMenu: true,
                key: 'addJobType',
                component: AddJobType
            },
            {
                path: '/settings/units/all',
                name: 'All Units',
                title: 'All Units',
                key: 'list',

                component: AllUnit
            },
            {
                path: '/settings/jobType/all',
                name: 'Job Type',
                title: 'Job Type',
                key: 'list',

                component: AllJobType
            },
            {
                path: '/settings/jobType/',
                name: 'Edit Job Type',
                title: 'Edit Job Type',
                dontShowOnMenu: true,
                key: 'editjobType',
                component: AddJobType
            },
            {
                path: '/settings/units/edit',
                name: 'Edit Unit',
                title: 'Edit Unit',
                dontShowOnMenu: true,
                key: 'editUnit',
                component: AddUnit
            },
            {
                path: '/settings/attribute/add',
                name: 'Add Attribute',
                title: 'Add Attribute',
                dontShowOnMenu: true,
                key: 'addAttribute',
                component: AddAttribute
            },
            {
                path: '/settings/attribute/all',
                name: 'All Attribute',
                title: 'All Attribute',
                key: 'list',
                component: AllAttribute
            },
            {
                path: '/settings/attribute/edit',
                name: 'Edit Attribute',
                title: 'Edit Attribute',
                dontShowOnMenu: true,
                key: 'editAttribute',
                component: AddAttribute
            },
            {
                path: '/settings/productOpeningStock',
                name: 'Product opening Stock',
                title: 'Product opening Stock',
                key: 'productOpeningStock',
                dontShowOnMenu: true,
                component: ProductOpeningStock
            },
            {
                path: '/settings/rawOpeningStock',
                name: 'Raw opening Stock',
                title: 'Raw opening Stock',
                key: 'rawOpeningStock',
                dontShowOnMenu: true,
                component: RawOpeningStock
            },
            {
                path: '/settings/list',
                name: 'All Opening Stock',
                title: 'All Opening Stock',
                key: 'listOpeningStock',
                component: ListOpeningStock
            }
        ]
    },

    {
        path: '/raw',
        name: 'Raw',
        icon: <UserAddOutlined/>,
        key: 'raw',
        authority: ['admin', 'user'],
        children: [
            {
                path: '/raw/add',
                name: 'Add Raw',
                title: 'Add Raw',
                dontShowOnMenu: true,
                key: 'add',
                component: AddRaw
            },
            {
                path: '/raw/all',
                name: 'All Raw',
                title: 'All Raw',
                key: 'list',
                component: AllRaw
            },
            {
                path: '/raw/settings',
                name: 'Raw Settings',
                title: 'Raw Settings',
                key: 'rawSettings',
                dontShowOnMenu: true,
                component: RawSettings
            },

            {
                path: '/raw/edit',
                name: 'Edit Raw',
                title: 'Edit Raw',
                dontShowOnMenu: true,
                key: 'edit',
                component: AddRaw
            }
        ]
    },
    {
        path: '/product',
        name: 'Product',
        icon: <AppstoreOutlined/>,
        key: 'product',
        authority: ['admin', 'user'],
        children: [
            {
                path: '/product/add',
                name: 'Add Product',
                title: 'Add Product',
                dontShowOnMenu: true,
                key: 'add',
                component: AddProduct
            },
            {
                path: '/product/all',
                name: 'All Product',
                title: 'All Product',
                key: 'list',
                component: AllProduct
            },
            {
                path: '/product/edit',
                name: 'Edit Product',
                title: 'Edit Product',
                dontShowOnMenu: true,
                key: 'edit',
                component: AddProduct
            },
            {
                path: '/product/variant/add',
                name: 'Add Variant',
                title: 'Add Variant',
                dontShowOnMenu: true,
                key: 'addVariant',
                component: AddVariant
            },
            {
                path: '/product/variant/edit',
                name: 'Edit variant',
                title: 'Edit variant',
                dontShowOnMenu: true,
                key: 'editVariant',
                component: AddVariant
            },
            {
                path: '/product/variant/all',
                name: 'All Variant',
                title: 'All Variant',
                key: 'list',
                component: AllVariants
            }
        ]
    },
    {
        path: '/supplier',
        name: 'Supplier',
        icon: <AppstoreOutlined/>,
        key: 'supplier',
        authority: ['admin', 'user'],
        children: [
            {
                path: '/supplier/add',
                name: 'Add Supplier',
                title: 'Add Supplier',
                dontShowOnMenu: true,
                key: 'add',
                component: AddSupplier
            },
            {
                path: '/supplier/edit',
                name: 'Edit Supplier',
                title: 'Edit Supplier',
                dontShowOnMenu: true,
                key: 'edit',
                component: AddSupplier
            },
            {
                path: '/supplier/all',
                name: 'All Supplier',
                title: 'All Supplier',
                key: 'list',
                component: AllSupplier
            }
        ]
    },
    {
        path: '/customer',
        name: 'Customer',
        icon: <AppstoreOutlined/>,
        key: 'customer',
        authority: ['admin', 'user'],
        children: [
            {
                path: '/customer/add',
                name: 'Add Customer',
                title: 'Add Customer',
                dontShowOnMenu: true,
                key: 'add',
                component: AddCustomer
            },
            {
                path: '/customer/edit',
                name: 'Edit Customer',
                title: 'Edit Customer',
                dontShowOnMenu: true,
                key: 'edit',
                component: AddCustomer
            },
            {
                path: '/customer/all',
                name: 'All Customer',
                title: 'All Customer',
                key: 'list',
                component: AllCustomer
            }
        ]
    },
    {
        path: '/challan',
        name: 'Challan',
        icon: <AppstoreOutlined/>,
        key: 'challan',
        authority: ['admin', 'user'],
        children: [
            {
                path: '/challan/add',
                name: 'Add Challan',
                title: 'Add Challan',
                //dontShowOnMenu: true,
                key: 'add',
                component: AddChallan
            },

            {
                path: '/challan/all',
                name: 'All Challan',
                title: 'All Challan',
                key: 'list',
                component: AllChallan
            },
            {
                path: '/challan/edit',
                name: 'Edit Challan',
                title: 'Edit Challan',
                dontShowOnMenu: true,
                key: 'edit',
                component: EditChallan
            }
        ]
    },
    {
            path: '/reducer',
            name: 'Rejection',
            icon: <AppstoreOutlined/>,
            key: 'reducer',
            authority: ['admin', 'user'],
            children: [
                {
                    path: '/reducer/add',
                    name: 'Add Rejection',
                    title: 'Add Rejection',
                    //dontShowOnMenu: true,
                    key: 'add',
                    component: AddRejection
                },

                {
                    path: '/reducer/all',
                    name: 'All Rejection',
                    title: 'All Rejection',
                    key: 'list',
                    component: AllRejections
                },
                {
                    path: '/reducer/edit',
                    name: 'Edit Rejection',
                    title: 'Edit Rejection',
                    dontShowOnMenu: true,
                    key: 'edit',
                    component: EditRejection
                }
            ]
    },
    {
         path: '/projection',
         name: 'Projection',
         icon: <AppstoreOutlined/>,
         key: 'projection',
         authority: ['admin', 'user'],
         children: [
             {
                 path: '/projection/add',
                 name: 'Add Projection',
                 title: 'Add Projection',
                 key: 'add',
                 component: AddProjection
             },
             {
                path: '/projection/all',
                name: 'All Projection',
                title: 'All Projection',
                key: 'all',
                component: AllProjection
             },
             {
                 path: '/projection/edit',
                 name: 'Edit Projection',
                 title: 'Edit Projection',
                 dontShowOnMenu: true,
                 key: 'edit',
                 component: EditProjection
             }
         ]
    },
    {
        path: '/dispatch',
        name: 'Dispatch',
        icon: <AppstoreOutlined/>,
        key: 'dispatch',
        authority: ['admin', 'user'],
        children: [
            {
                path: '/dispatch/add',
                name: 'Add Dispatch',
                title: 'Add Dispatch',
                //dontShowOnMenu: true,
                key: 'add',
                component: AddOutward
            },

            {
                path: '/dispatch/all',
                name: 'All Dispatch',
                title: 'All Dispatch',
                key: 'list',
                // component: AllOutward
                component: AllDispatch
            },
            {
                path: '/dispatch/edit',
                name: 'Edit Dispatch',
                title: 'Edit Dispatch',
                dontShowOnMenu: true,
                key: 'edit',
                component: EditOutward
            }
        ]
    },
    {
        path: '/purchaseOrder',
        name: 'Purchase Order',
        icon: <AppstoreOutlined/>,
        key: 'purchaseOrder',
        authority: ['admin', 'user'],
        children: [
            {
                path: '/purchaseOrder/add',
                name: 'Add Purchase Order',
                title: 'Add Purchase Order',
                dontShowOnMenu: true,
                key: 'add',
                component: AddPurchaseOrder
            },

            {
                path: '/purchaseOrder/edit',
                name: 'Edit Purchase Order',
                title: 'Edit Purchase Order',
                key: 'edit',
                dontShowOnMenu: true,
                component: EditPurchaseOrder
            },
            {
                path: '/purchaseOrder/all',
                name: 'All Purchase Order',
                title: 'All Purchase Order',
                key: 'list',
                component: AllPurchaseOrder
            },
            {
                path: '/purchaseOrder/details',
                name: 'Purchase Order Details',
                title: 'Purchase Order Details',
                key: 'details',
                dontShowOnMenu: true,
                component: PurchaseOrderDetails
            }
        ]
    },

    {
        path: '/supplierOrder',
        name: 'Supplier Order',
        icon: <AppstoreOutlined/>,
        key: 'supplierOrder',
        authority: ['admin', 'user'],
        children: [
            {
                path: '/supplierOrder/addPurchaseOrder',
                name: 'Piece Order',
                title: 'Add Piece Order',
                key: 'add',
                component: AddPieceOrder
            },
            {
                path: '/supplierOrder/addJobWork',
                name: 'Raw Order',
                title: 'Add Raw Material Order',
                key: 'addJob',
                component: AddRawOrder
            },
            {
                path: '/supplierOrder/all',
                name: 'All Orders',
                title: 'All Orders',
                key: 'all',
                component: AllSupplierOrders
            },
            {
                path: '/supplierOrder/edit',
                name: 'Edit Supplier Order',
                title: 'Edit Supplier Order',
                key: 'edit',
                dontShowOnMenu: true,
                component: EditSupplierOrder
            },
            {
                path: '/supplierOrder/editRaw',
                name: 'Edit Supplier Order',
                title: 'Edit Supplier Order',
                key: 'editRaw',
                dontShowOnMenu: true,
                component: EditRawOrder
            },
            /* {
                 path: '/supplierOrder/pendingJobs',
                 name: 'Pending Jobs',
                 title: 'Pending Supplier Jobs',
                 key: 'pendingJobs',
                 component: PendingJobs
             }*/
        ]
    },

    {
        path: '/reports',
        name: 'Reports',
        icon: <UserAddOutlined/>,
        key: 'reports',
        authority: ['admin', 'user'],
        children: [
            {
                path: '/reports/rawMaterial',
                name: 'Raw Material Report',
                title: 'Raw Material Report',
                key: 'rawMaterial',
                component: RawReport
            },
            {
                path: '/reports/challanProductReport',
                name: 'Challan Product Report',
                title: 'Challan Product Report',
                key: 'challanProductReport',
                component: ChallanProductReport
            },
            {
                path: '/reports/supplierReport',
                name: 'Supplier Report',
                title: 'Supplier Report',
                key: 'supplierReport',
                component: SupplierReport
            },
            {
                path: '/reports/poReport',
                name: 'PO Report',
                title: 'PO Report',
                key: 'poReport',
                component: PoReport
            },
            {
                path: '/reports/supplierPoReport',
                name: '',
                title: 'supplierPOReport',
                key: 'supplierPOReport',
                component: SupplierPoReport
            },
            {
                path: '/reports/weightList',
                name: 'Weight Report',
                title: 'Weight Report',
                key: 'list',
                component: ChallanWeightList
            },
            {
                path: '/reports/dispatchReport',
                name: 'Dispatch Report',
                title: 'Dispatch Report',
                key: 'dispatchReport',
                component: dispatchReport
            },

        ]
    },
    /*  {
          path: '/ledger',
          name: 'Ledger Report',
          icon: <UserAddOutlined/>,
          key: 'ledger',
          authority: ['admin', 'user'],
          children: [
              {
                  path: '/ledger/rawMaterial',
                  name: 'Supplier Wise Raw Ledger',
                  title: 'Supplier Wise Raw Ledger',
                  key: 'rawMaterialLedger',
                  component: RawLedger
              },
              {
                  path: '/ledger/products',
                  name: 'Supplier Wise Products Ledger',
                  title: 'Supplier Wise Products Ledger',
                  key: 'productsLedger',
                  component: ProductLedger
              }
          ]
      }*/
]

{
    /* <editor-fold desc="Functions Exports"> */
}
export const getUrlPushWrapper = (keyString, query) => {
    return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {
    if (!params) params = {}

    let keyArr = keyString.split('.')

    let val = _.find(menu, p => p.key === keyArr[0])

    if (!val) {
        return `/`
    }

    if (keyArr.length === 2) {
        val = _.find(val.children, p => p.key === keyArr[1])
    }

    if (!val) {
        return `/`
    }

    let queryString = Object.keys(params)
        .map(key => key + '=' + params[key])
        .join('&')

    return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params) => {
    let obj = getUrlObject(keyString)

    if (obj) {
        const path = new Path(obj.path)

        return push(path.build(params))
    }

    return 'error'
}

export const getUrlParams = (keyString, route) => {
    let obj = getUrlObject(keyString)

    if (obj) {
        const path = new Path(obj.path)

        return path.test(route)
    }

    return {error: true}
}

export const getUrlObject = keyString => {
    let keyArr = keyString.split('.')

    let val = _.find(menu, p => p.key === keyArr[0])

    if (!val) {
        return `/`
    }

    if (keyArr.length === 2) {
        val = _.find(val.children, p => p.key === keyArr[1])
    }

    if (!val) {
        return `/`
    }

    return val
}
//</editor-fold>

export default menu
