import React, {useEffect, useRef, useState} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {

    AutoComplete,
    Typography,
    Button,
    Input,
    DatePicker,

    Card,
    Select,
    Upload,
    Spin,
    Row,
    Col,

    Popconfirm, Modal, Divider, Tag
} from 'antd'
import 'react-quill/dist/quill.snow.css'
import {

    SearchOutlined,
    SaveOutlined,
    UserOutlined,
    PrinterOutlined,
    PhoneOutlined,
    EnvironmentOutlined,
    MailOutlined,
    UploadOutlined, TableOutlined,
} from '@ant-design/icons'
import {notification} from 'antd/lib/index'
import {PrintRejectionAction} from '../action'
import styles from './styles.less'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import SearchProduct from './searchProduct'
import {TableComp} from 'sz-react-utils4'
import SearchSupplier from '../../../components/search/supplier'
import {dateFormat} from '../../../request'
import {AddAction} from '../action'

import {API_URL} from '../../../request'

import ChallanWeight from '../../challan/view/chllanWeightSection';
import {NextChallanIdAction} from "../../challan/action";

let proArr = []
const {TextArea} = Input
const {Text} = Typography
const {Option} = Select

const initialObj = {
    rejectionNumber: null,
    fromSupplierId: null,
    toSupplierId: null,
    fromSupplierName: null,
    toSupplierName: null,
    rejectionDate: moment(),
    supplierChallanNumber: null,
    challanId: null,
    challanDocId: null,
    notes: 0,
    totalRawQty: 0,
    totalProductQty: 0,
    totalQty: 0,

    vehicleNumber: null,
    vehicleName: null,

    fromAddress: {
        name: null,
        mobile: null,
        email: null,
        state: null,
        city: null,
        address: null,
        pinCode: null
    },
    toAddress: {
        name: null,
        mobile: null,
        email: null,
        state: null,
        city: null,
        address: null,
        pinCode: null
    },
    rejectionRef: null,
    weighingSlip: null,
    totalWeight: null,
    totalApproxWeight: 0

}
const AddRejection = props => {
    const dispatch = useDispatch()

    const [spinLoader, setSpinLoader] = useState(false)
    let [showSearch, setSearch] = useState(false)
    let [arr, setArr] = useState([])
    let [fromAddress, setFromAddress] = useState([])
    let [toAddress, setToAddress] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [weightArr, setWeightArr] = useState([])
    const [showPreview, setShowPreview] = useState(false)
    const childFunc = React.useRef(null)
    const childFuncSecond = React.useRef(null)
    const [obj, setObj] = useState(initialObj)
    let [cols, setCols] = useState([
        {
            title: 'Product',
            key: 'productName',
            dataKey: 'productName',
            width: 200,

        },
        {
            title: 'Code',
            key: 'productCode',
            dataKey: 'productCode',
            width: 150,

        },
        {
            title: 'PO',
            key: 'purchaseOrderNumber',
            width: 100,
            dataKey: 'purchaseOrderNumber',

        },


        {
            title: 'Reject Qty',
            key: 'qty',
            width: 200,
            dataKey: 'qty',
            render: (val, record) => {
                 return (
                     <Row>
                         <Col>
                             <Input
                                 className={styles.inputNumber}
                                 value={val}
                                 style={{width: 100}}
                                 type={'number'}
                                 min={1}
                                 onChange={e => {
                                     let tempArr = _.cloneDeep(proArr)
                                     let findIn = _.find(tempArr, (v, k) => {
                                         //return v._id == record._id
                                         return (v.poDocId == record.poDocId && v.variantId == record.variantId)
                                     })
                                     if (findIn) {
                                         findIn.qty = e.target.value
                                     }
                                     proArr = tempArr
                                     setArr(tempArr)
                                 }}
                             />
                         </Col>

                     </Row>
                 )
            }
        },
        {
            title: 'Size', key: 'size', dataKey: 'size', render: (val, record) => {
                return (
                    <div>{val} {record.sizeUnit}
                    </div>
                )
            }
        },
        {title: 'Colour', key: 'colour', dataKey: 'colour'},
        {
            title: 'Weight',
            key: 'weight',
            dataKey: 'weight',
            render: (val, record) => {
                return (
                    <div>{val} {record.weightUnit}
                    </div>
                )
            }

        },

        {
            title: 'Approx Weight',
            key: 'approxWeight',

            render: (val, record) => {
                return <div>{returnApproxWeight(record)}</div>
            }
        },
        {
            title: 'Remove',
            key: 'key',
            fixed: 'right',
            render: (val, record) => {
                return (
                    <div>
                        <Popconfirm
                            title="Are you sure to remove?"
                            onConfirm={() => {
                                proArr = _.reject(proArr, function (e) {
                                    return record._id == e._id
                                    //return (record.poDocId == e.poDocId && record.variantId == e.variantId)
                                })
                                setArr(proArr)
                            }}
                            onCancel={() => {
                            }}
                            okText="Yes"
                            cancelText="No">
                            <a>Remove</a>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ])


    const uploadProps = {
        action: `${API_URL}/filesuploader`,

        onChange(info) {
            if (info.file.status !== 'uploading') {
            }
            if (info.file.status === 'done') {

                setObj({
                    ...obj,
                    weighingSlip: [
                        {
                            uid: info.file.uid,
                            name: info.file.name,
                            path: info.file.response.files[0].path,
                            url: '/downloads/' + info.file.response.files[0].filename
                        }
                    ]
                })
            }
        }
    }
    const handlePreview = async (file) => {
        let url
        if (file && file.response && file.response.files[0].path) {
            file.response.path = file.response.files[0].path.replace(/^public/, '')
            url = `${API_URL}/${file.response.path}`
        } else {
            url = `${API_URL}/${file.url}`
        }

        window.open(url, '_blank')
    }

    useEffect(() => {
        fetchNextId()
    }, [])

    const fetchNextId = async () => {
        let {data} = await NextChallanIdAction()
        if (data) {
            setObj({
                ...obj,
                rejectionNumber: data
            })
        }
    }

    useEffect(
        () => {
            calTotalQty()
            dispatch({type: 'SET_CHALLAN_ITEMS', data: arr});
        },
        [arr]
    )
    const returnApproxWeight = item => {
        try {
            if (item.type == 'Product') {
                if (item.qty && item.weight) {
                    return parseInt(parseFloat(item.qty) * parseFloat(item.weight))
                } else {
                    return 0;
                }
            } else {
                return item.qty;
            }

        } catch (e) {
            return 0

        }

    }


    const onPreview = () => {

        setShowPreview(true)
    }






    const calTotalQty = () => {
        let rQ = 0
        let pQ = 0
        let aW = 0
        _.each(arr, v => {
            if (v.type == 'Product') {
                if (v.qty) {
                    pQ += parseFloat(v.qty)
                }
            }

            if (v.qty && v.weight) {
                aW += parseFloat(parseFloat(v.qty) * parseFloat(v.weight))
            }
        })

        setObj({
            ...obj,
            totalRawQty: (parseFloat(rQ)),
            totalProductQty: parseFloat(parseFloat(pQ)),
            totalQty: parseFloat(parseFloat(rQ + pQ)),
            totalApproxWeight: parseFloat(parseFloat(aW).toFixed(2))
        })
    }
    const saveOnly = async (printRejection) => {

        if (!obj.fromSupplierId) {
            notification.error({
                message: 'Required',
                description: 'Choose From Supplier'
            })
            return
        }
        if (!obj.toSupplierId) {
            notification.error({
                message: 'Required',
                description: 'Choose To Supplier'
            })
            return
        }
        if (!obj.rejectionDate) {
            notification.error({
                message: 'Required',
                description: 'Choose Rejection Date'
            })
            return
        }

        if (obj.fromSupplierId == obj.toSupplierId) {
            notification.error({
                description: 'From Supplier and To Supplier can not be same'
            })
            return
        }
        if (!obj.rejectionNumber) {
            notification.error({
                description: 'Enter Rejection Challan Number'
            })
            return
        }

        /*if (!obj.totalQty) {
                notification.error({message: 'Required', description: 'Fill All Quantities '});
                return
            }*/
        if (!arr.length) {
            notification.error({
                message: 'Required',
                description: 'Choose Products/Raw materials'
            })
            return
        }

        let findIn = _.find(arr, v => {
            return !v.qty
        })

        if (findIn) {
            notification.error({
                message: 'Required',
                description: `Enter quantity for ${findIn.productName}`
            })
            return
        }


        let findWei = _.find(weightArr, v => {
            return !v.weight
        })
        if (findWei) {
            notification.error({
                message: 'Required',
                description: `Enter weight for ${findWei.productName}`
            })
            return
        }





        setSpinLoader(true);

        let {success, message, data} = await AddAction({
            ...obj,
            arr,
            weightArr

        })
        setSpinLoader(false)
        if (success) {

            setObj(initialObj)

            notification.success({message: 'Success', description: 'Rejection Saved'})
            if (printRejection) {
                const rejectData = await PrintRejectionAction(data._id)
                if (rejectData.success) {
                    window.open(rejectData.url)
                }
            }

            setTimeout(() => {
                window.location.reload(true)
            }, 500)

            /* setArr([])
                   proArr = []
                   poArr = []
                   fetchPo()
                   fetchNextId();*/
            return
        } else {
            notification.error({message: 'Error', description: message})
        }
    }

    const previewColss = [
        {
            title: 'Product',
            key: 'productName',
            dataKey: 'productName',
            render: (val, record) => {
                return (
                    <div>{val} ({record.size} {record.sizeUnit})</div>
                )
            }

        },
        /*  {
              title: 'Size',
              key: 'size',
              dataKey: 'size',

          },*/
        {
            title: 'Qty',
            key: 'qty',
            width: 100,
            render: val => {
                return <Tag color={'green'}>{val}</Tag>
            }
        },


    ]

    return (
        <PageHeaderWrapper title={'New Rejection'}>
            <Spin spinning={spinLoader}>
                <div className={styles.cardStyle}>
                    <Row>
                        <Col className={styles.filterCol}>
                            <b>From Supplier</b> <a style={{float: 'right'}} onClick={() => {

                            childFunc.current()
                            childFuncSecond.current()

                        }}> Refresh</a>
                            <SearchSupplier
                                isMeghnaOffice={true}
                                value={obj.fromSupplierName}
                                childFunc={childFunc}
                                onSelect={e => {

                                    setObj({
                                        ...obj,
                                        fromSupplierId: e._id,
                                        fromSupplierName: e.name,
                                        fromAddress: {
                                            name: null,
                                            mobile: null,
                                            email: null,
                                            state: null,
                                            city: null,
                                            address: null,
                                            pinCode: null
                                        }
                                    })
                                    setVehicles(e.vehicles || [])
                                    setFromAddress(
                                        e.address.map((v, idx) => {
                                            return {
                                                key: v._id,
                                                value: v.name,
                                                info: v,
                                                label: (
                                                    <div>
                                                        <div>
                                                            <b>
                                                                <UserOutlined/> {v.name}
                                                            </b>
                                                        </div>
                                                        <div className={styles.autoCompleteEmail}>
                                                            <PhoneOutlined/> {v.mobile}
                                                        </div>
                                                        <div className={styles.autoCompleteEmail}>
                                                            <MailOutlined/> {v.email}
                                                        </div>
                                                        <div className={styles.autoCompleteAddress}>
                                                            <EnvironmentOutlined/> {v.address}
                                                        </div>
                                                        <div className={styles.autoCompleteAddress}>{`${
                                                            v.city
                                                        }, ${v.pinCode}`}</div>
                                                        <div className={styles.autoCompleteAddress}>
                                                            {v.state}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    )
                                }}
                            />
                        </Col>
                        <Col className={styles.filterCol}>
                            <b>To Supplier</b>
                            <SearchSupplier
                                childFuncSecond={childFuncSecond}
                                value={obj.toSupplierName}
                                onSelect={e => {
                                    dispatch({type: 'SET_CHALLAN_ITEMS', data: []});
                                    setObj({
                                        ...obj,
                                        toSupplierId: e._id,
                                        toSupplierName: e.name,
                                        toAddress: {
                                            name: null,
                                            mobile: null,
                                            email: null,
                                            state: null,
                                            city: null,
                                            address: null,
                                            pinCode: null
                                        }
                                    })
                                    setToAddress(
                                        e.address.map((v, idx) => {
                                            return {
                                                key: v._id,
                                                value: v.name,
                                                info: v,
                                                label: (
                                                    <div>
                                                        <div>
                                                            <b>
                                                                <UserOutlined/> {v.name}
                                                            </b>
                                                        </div>
                                                        <div className={styles.autoCompleteEmail}>
                                                            <PhoneOutlined/> {v.mobile}
                                                        </div>
                                                        <div className={styles.autoCompleteEmail}>
                                                            <MailOutlined/> {v.email}
                                                        </div>
                                                        <div className={styles.autoCompleteAddress}>
                                                            <EnvironmentOutlined/> {v.address}
                                                        </div>
                                                        <div className={styles.autoCompleteAddress}>{`${
                                                            v.city
                                                        }, ${v.pinCode}`}</div>
                                                        <div className={styles.autoCompleteAddress}>
                                                            {v.state}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    )
                                }}
                            />
                        </Col>

                        <Col className={styles.filterCol}>
                            <b>Rejection Date</b>
                            <br/>
                            <DatePicker
                                format={dateFormat}
                                value={obj.rejectionDate}
                                onChange={e => {
                                    setObj({
                                        ...obj,
                                        rejectionDate: e
                                    })
                                }}
                            />
                        </Col>

                        <Col className={styles.filterCol}>
                            <b>Challan Number</b>
                            <br/>
                            <Input value={obj.rejectionNumber} disabled={true}/>
                        </Col>
                        <Col className={styles.filterCol}>
                            <b>Reference</b>
                            <br/>
                            <Input
                                onChange={e => {
                                    setObj({
                                        ...obj,
                                        rejectionRef: e.target.value
                                    })
                                }}
                                value={obj.rejectionRef}
                            />
                        </Col>

                    </Row>
                </div>

                <Row>
                    <Col className={styles.addressInp}>
                        <Card>
                            <Text type={'success'}>Sender Address</Text>
                            <Row>
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>Name</b>
                                    </div>
                                    <AutoComplete
                                        className={styles.addInput}
                                        options={fromAddress}
                                        // value={obj && obj.fromAddress && obj.fromAddress.name}
                                        placeholder="Type Billing Name"
                                        onSelect={(e, option) => {
                                            const {info} = option
                                            setObj({
                                                ...obj,
                                                fromAddress: {
                                                    name: info.name,
                                                    mobile: info.mobile,
                                                    email: info.email,
                                                    address: info.address,
                                                    state: info.state,
                                                    city: info.city,
                                                    pinCode: info.pinCode
                                                }
                                            })
                                        }}
                                    />
                                </Col>
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>Mobile</b>
                                    </div>
                                    <Input
                                        onChange={e => {
                                            obj.fromAddress.mobile = e.target.value
                                            setObj({
                                                ...obj
                                            })
                                        }}
                                        value={obj && obj.fromAddress && obj.fromAddress.mobile}
                                    />
                                </Col>
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>Email</b>
                                    </div>
                                    <Input
                                        className={styles.addInput}
                                        onChange={e => {
                                            obj.fromAddress.email = e.target.value
                                            setObj({
                                                ...obj
                                            })
                                        }}
                                        value={obj && obj.fromAddress && obj.fromAddress.email}
                                    />
                                </Col>

                                <Col className={styles.addInp}>
                                    <div>
                                        <b>State</b>
                                    </div>
                                    <Input
                                        className={styles.addInput}
                                        onChange={e => {
                                            obj.fromAddress.state = e.target.value
                                            setObj({
                                                ...obj
                                            })
                                        }}
                                        value={obj && obj.fromAddress && obj.fromAddress.state}
                                    />
                                </Col>
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>City</b>
                                    </div>
                                    <Input
                                        className={styles.addInput}
                                        onChange={e => {
                                            obj.fromAddress.city = e.target.value
                                            setObj({...obj})
                                        }}
                                        value={obj && obj.fromAddress && obj.fromAddress.city}
                                    />
                                </Col>
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>Pin Code</b>
                                    </div>
                                    <Input
                                        className={styles.addInput}
                                        onChange={e => {
                                            obj.fromAddress.pinCode = e.target.value
                                            setObj({...obj})
                                        }}
                                        value={obj && obj.fromAddress && obj.fromAddress.pinCode}
                                    />
                                </Col>

                                <Col className={styles.addInp}>
                                    <div>
                                        <b>Address</b>
                                    </div>
                                    <Input
                                        className={styles.addAddress}
                                        onChange={e => {
                                            obj.fromAddress.address = e.target.value
                                            setObj({...obj})
                                        }}
                                        value={obj && obj.fromAddress && obj.fromAddress.address}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Text type={'warning'}>Receiver Address</Text>
                            <Row>
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>Name</b>
                                    </div>
                                    <AutoComplete
                                        className={styles.addInput}
                                        options={toAddress}
                                        // value={obj && obj.toAddress && obj.toAddress.name}
                                        placeholder="Type Billing Name"
                                        onSelect={(e, option) => {
                                            const {info} = option
                                            setObj({
                                                ...obj,
                                                toAddress: {
                                                    name: info.name,
                                                    mobile: info.mobile,
                                                    email: info.email,
                                                    address: info.address,
                                                    state: info.state,
                                                    city: info.city,
                                                    pinCode: info.pinCode
                                                }
                                            })
                                        }}
                                    />
                                </Col>{' '}
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>Mobile</b>
                                    </div>

                                    <Input
                                        className={styles.addInput}
                                        onChange={e => {
                                            obj.toAddress.mobile = e.target.value
                                            setObj({...obj})
                                        }}
                                        value={obj && obj.toAddress && obj.toAddress.mobile}
                                    />
                                </Col>
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>Email</b>
                                    </div>
                                    <Input
                                        className={styles.addInput}
                                        onChange={e => {
                                            obj.toAddress.email = e.target.value
                                            setObj({...obj})
                                        }}
                                        value={obj && obj.toAddress && obj.toAddress.email}
                                    />
                                </Col>
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>State</b>
                                    </div>
                                    <Input
                                        className={styles.addInput}
                                        onChange={e => {
                                            obj.toAddress.state = e.target.value
                                            setObj({...obj})
                                        }}
                                        value={obj && obj.toAddress && obj.toAddress.state}
                                    />
                                </Col>{' '}
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>City</b>
                                    </div>
                                    <Input
                                        className={styles.addInput}
                                        onChange={e => {
                                            obj.toAddress.city = e.target.value
                                            setObj({...obj})
                                        }}
                                        value={obj && obj.toAddress && obj.toAddress.city}
                                    />
                                </Col>
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>Pincode</b>
                                    </div>
                                    <Input
                                        className={styles.addInput}
                                        onChange={e => {
                                            obj.toAddress.pinCode = e.target.value
                                            setObj({...obj})
                                        }}
                                        value={obj && obj.toAddress && obj.toAddress.pinCode}
                                    />
                                </Col>
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>Address</b>
                                    </div>
                                    <Input
                                        className={styles.addAddress}
                                        onChange={e => {
                                            obj.toAddress.address = e.target.value
                                            setObj({...obj})
                                        }}
                                        rows={3}
                                        value={obj && obj.toAddress && obj.toAddress.address}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                {showSearch ? (
                    <SearchProduct
                        fromSupplierId={obj.toSupplierId}
                        toSupplierId={obj.fromSupplierId}
                        supplierName={obj.toSupplierName}
                        onSeleced={tempArr => {


                            let testArr = _.cloneDeep(arr)
                            _.each(tempArr, (val, kk) => {
                                let findIn = _.find(testArr, vv => {
                                    return vv._id == val._id
                                })

                                if (!findIn) {
                                    testArr.push(val)
                                } else {
                                    findIn.qty = val.qty;
                                }

                            })
                            setArr(testArr)
                            proArr = testArr
                        }}
                        onClose={() => {
                            setSearch(false)
                        }}
                    />
                ) : null}

                <Card
                    title={`Collections (${arr.length})`}
                    extra={
                        <Button onClick={() => {
                            if (obj.toSupplierId) {

                                setSearch(true);

                            } else {
                                notification.error({
                                    message: 'Choose supplier'
                                })
                            }

                        }}>
                            <SearchOutlined/> Search Items
                        </Button>
                    }>
                    <TableComp
                        extraProps={{scroll: {x: true}}}
                        dataSource={arr}
                        pagination={{
                            defaultPageSize: 200,
                            pageSizeOptions: ['200', '400', '800', '1000'],
                            showSizeChanger: true
                        }}
                        columns={cols}
                    />
                    <Row style={{marginTop: 10}}>
                        <Col col={18}>
                            <b type={'warning'}>Total Approximate Weight : </b>
                        </Col>
                        <Col col={6}>
                            {' '}
                            <b style={{paddingLeft: 10}}> {obj.totalApproxWeight} KG </b>
                        </Col>
                    </Row>
                </Card>
                <Card title={`Actual Weights`}>
                    <ChallanWeight
                        setWeight={(e) => {
                            if (e && e.weightArr) {
                                setWeightArr(e.weightArr)
                            }
                            setObj({
                                ...obj,
                                totalWeight: e.totalWeight
                            })

                        }}

                    />
                </Card>

                <Card>
                    <Row gutter={10} className={styles.noteArea}>
                        <Col>
                            <div>
                                <b>Note if any</b>
                            </div>

                            <TextArea
                                style={{width: 300}}
                                onChange={e => {
                                    setObj({
                                        ...obj,
                                        notes: e.target.value
                                    })
                                }}
                                rows={2}
                            />
                        </Col>

                        <Col className={styles.totalQty}>
                            <b>Total Qty : {obj.totalQty} </b>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col>
                            <div>
                                <b>Choose Vehicle </b>
                            </div>
                            <Select
                                style={{width: 300}}
                                onChange={e => {
                                    let fndIn = _.find(vehicles, v => {
                                        return (v.number = e)
                                    })
                                    if (fndIn) {
                                        setObj({
                                            ...obj,
                                            vehicleName: fndIn.name,
                                            vehicleNumber: fndIn.number
                                        })
                                    }
                                }}>
                                <Option key={null} value={null}>
                                    Choose
                                </Option>
                                {vehicles.map((val, kk) => {
                                    return (
                                        <Option key={val.number} value={val.number}>
                                            {val.name}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Col>
                        <Col>
                            <div>
                                <b>Vehicle Name</b>
                                <Input
                                    value={obj.vehicleName}
                                    onChange={e => {
                                        setObj({
                                            ...obj,
                                            vehicleName: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <b>Vehicle Number</b>
                                <Input
                                    value={obj.vehicleNumber}
                                    onChange={e => {
                                        setObj({
                                            ...obj,
                                            vehicleNumber: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <b>Total Weight</b>
                                <Input
                                    type={'number'}
                                    disabled={true}
                                    value={obj.totalWeight}
                                    onChange={e => {
                                        setObj({
                                            ...obj,
                                            totalWeight: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col>
                            <div style={{marginTop: 10}}>
                                <b>Weighing Slip</b>
                                <div>
                                    <Upload onPreview={handlePreview} {...uploadProps} maxCount={1} accept="image/*">
                                        <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                                    </Upload>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div style={{marginTop: 10}}>
                                <b>Rejection Photo</b>
                                <div>
                                    <Upload onPreview={handlePreview} action={`${API_URL}/filesuploader`}
                                            onChange={(info) => {
                                                if (info.file.status === 'done') {
                                                    setObj({
                                                        ...obj,
                                                        rejectionPhoto: [
                                                            {
                                                                uid: info.file.uid,
                                                                name: info.file.name,
                                                                path: info.file.response.files[0].path,
                                                                url: '/downloads/' + info.file.response.files[0].filename
                                                            }
                                                        ]
                                                    })
                                                }

                                            }} maxCount={1} accept="image/*">
                                        <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                                    </Upload>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>
                <Card>
                    <Row className={styles.btnctr}>
                        <Col style={{marginRight: 10}}>
                            <Button
                                //disabled={loader}
                                onClick={() => {
                                    onPreview()
                                }}
                                type={'secondary'}>
                                <TableOutlined/> Preview Rejection
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                disabled={spinLoader}
                                loading={spinLoader}
                                style={{marginRight: '1em'}}
                                onClick={() => {
                                    saveOnly(false)
                                }}
                                type={'primary'}>
                                <SaveOutlined/> Save Only
                            </Button>
                            <Button
                                disabled={spinLoader}
                                loading={spinLoader}
                                onClick={() => {
                                    saveOnly(true)
                                }}
                                type={'primary'}>
                                <PrinterOutlined/> Save & Print
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Spin>

            <Modal
                title="Preview Rejection Challan"
                centered
                visible={showPreview}
                onOk={() => setShowPreview(false)}
                onCancel={() => setShowPreview(false)}
                width={1400}>
                <div>
                    <Row style={{marginBottom: 10}}>
                        <Col span={16}>
                            <div><b>Supplier</b></div>
                            <div>{obj.toSupplierName}</div>
                            <div>{obj.toAddress ? obj.toAddress.address : ''}</div>
                        </Col>
                        <Col span={4}>
                            <div><b>Challan Number</b></div>

                            <div>{obj.rejectionNumber}</div>
                        </Col>
                        <Col span={4}>
                            <div><b>Total Items</b></div>
                            <div>{obj.totalQty}</div>
                        </Col>
                    </Row>
                    <Divider/>
                    <TableComp
                        dataSource={arr}

                        extraProps={{scroll: {x: true}}}
                        pagination={{
                            defaultPageSize: 500,
                            pageSizeOptions: ['100', '250', '500', '1000'],
                            showSizeChanger: true
                        }}
                        columns={previewColss}
                    />
                </div>
            </Modal>
        </PageHeaderWrapper>
    )
}
export default AddRejection
