import React, {useState, useRef, useEffect} from 'react'
import {Select, Spin} from 'antd'
import 'react-quill/dist/quill.snow.css'
import _ from 'lodash'


import {ListAction,SearchPOToReport} from '../../containers/purchaseOrder/action'



const SearchPo = props => {
  const [optionArr, setOptions] = useState([])
  const [searching, setSearching] = useState(false)
  const textInput = useRef({
    name: null,
    _id: null,
    vehicles: null,
    address: []
  })


  const handleSearchFunc = async value => {
    setSearching(true);
    setOptions([])
    textInput.current.name = ''
   if(props.data) {
    
    let {error, data} = await SearchPOToReport({
      results: 200,
      supplierId: props.data._id,
      regExFilters: ['poNumber'],
      sortField: 'poNumber',
      sortOrder: 'descend',
  })
  if (!error) {
    
      setOptions(data);
    } else {
      setOptions([]);
    }
   }
   else{
    let {error, data} = await ListAction({
   
      results: 200,
      regExFilters: ['poNumber'],
      sortField: 'poNumber',
      sortOrder: 'descend',
    })
    if (!error) {
 
        setOptions(data);
      } else {
        setOptions([]);
      }

   }
   
    setSearching(false);
  }

  if (props.data){
    useEffect(() => {
      handleSearchFunc(null)
    }, [props.data._id])
  }
  useEffect(() => {
    handleSearchFunc(null)
  }, [])
  useEffect(() => {
    console.log(props)
    textInput.current.name = props.editValue
  }, [props.editValue])

  return (
      <div>

          {/* <Select>
                {
                    options.map((val, kkk) => {
                        return <Option value={val._id}>{val.name}</Option>
                    })
                }
            </Select>*/}

        <Select
            style={{width: '250px'}}
            showSearch
            allowClear
            size={props.size ? props.size : 'medium'}
            value={textInput.current.name}
            ref={textInput}
            filterOption={(input, option) => {


              return (
                  option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
              )
            }}
            onClear={() => {
              props.onSelect({
                name: null,
                _id: null,
                purchaseOrderNumber: null

              })
            }}
            onChange={(e) => {

              let findIn = _.find(optionArr, (v) => {
                return v._id == e;
              })

              if (findIn) {

                props.onSelect({
                  name: findIn.purchaseOrderNumber,
                  _id: findIn._id,
                  purchaseOrderNumber: findIn.purchaseOrderNumber

                })
                textInput.current.name = findIn.purchaseOrderNumber;
              } else {
                props.onSelect({
                  name: null,
                  _id: null,
                  purchaseOrderNumber: null


                })
                textInput.current.name = null
              }
            }}
        >

          {optionArr &&
          optionArr.map((mcc, ii) => {
            return (
                <Select.Option value={mcc._id} key={mcc._id}>
                  {mcc.purchaseOrderNumber}
                </Select.Option>
            )
          })}
        </Select>

        {searching && <Spin size="small" style={{marginLeft: 10}}/>}
      </div>
  )
}
export default SearchPo
