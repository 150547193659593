import React, {useState, useRef, useMemo, useEffect} from 'react'
import {Select, Row, Col, Spin} from 'antd'
import _ from 'lodash'
import {useDispatch} from "react-redux";

const WeightClassification = React.memo(function WeightClassification(props) {

    const dispatch = useDispatch();

    const [arr, setArr] = useState([])

    const sampArr = useMemo(() => {


        let tempArr = props.arr;
        _.each(tempArr, (v) => {
            if (v.weight) {
                v.totalWeight = parseFloat(parseFloat(parseFloat(v.orderQty) * parseFloat(v.weight)).toFixed(2));
            } else {
                v.weight = 0;
                v.totalWeight = 0;
            }

        })


        tempArr = _.groupBy(tempArr, (v) => v.variantId);


        let findProName = (pId) => {
            let findIn = _.find(props.arr, (v) => {
                return v.variantId == pId;
            })
            if (findIn && findIn.productName) {

                return {
                    productName: findIn.productName,
                    productId: findIn.productId,
                    bom: findIn.bom,
                    unitWeight: parseFloat(parseFloat(findIn.weight).toFixed(2))
                }
            } else {
                return {
                    productName: null,
                    productId: null,
                    bom: null,
                    unitWeight: null
                };
            }
        }


        let getTotalQty = (elem) => {

            let totalWeight = 0;
            totalWeight = _.sumBy(elem, (inqty) => inqty.totalWeight);
            return parseFloat(parseFloat(totalWeight).toFixed(2));

        }

        let finalArr = [];
        _.each(tempArr, (v, k) => {


            finalArr.push({
                variantId: k,
                productName: findProName(k).productName,
                productId: findProName(k).productId,
                bom: findProName(k).bom,
                productWeight: getTotalQty(v),
                unitWeight: findProName(k).unitWeight,
                // raws: getBoms(v)
            })

        })
        _.each(finalArr, (v, k) => {
            _.each(v.bom, (vv) => {
                let percent = parseFloat(parseFloat((vv.qty) / v.unitWeight).toFixed(2))
                vv.totalWeight = percent * v.productWeight;
                vv.totalWeight = parseFloat(parseFloat(vv.totalWeight).toFixed(2))
            })

        })


        finalArr = _.groupBy(finalArr, (v) => v.productId);


        let touchArr = []
        _.each(finalArr, (v, k) => {

            let subArr = [];
            _.each(v, (vv) => {
                subArr = [...subArr, ...vv.bom]
            })

            let tt = _.map(_.groupBy(subArr, 'rawId'), (o, idx) => {
                return {
                    id: idx,
                    rawName: o[0].rawName,
                    weight: parseFloat(_.sumBy(o, 'totalWeight')).toFixed(2)
                }
            })


            let obj = {
                productId: k,
                productName: v[0].productName,
                weight: parseFloat(_.sumBy(v, (kk) => kk.productWeight ? kk.productWeight : 0)).toFixed(2),
                raws: subArr,
                childs: tt
            }

            touchArr.push(obj);

        })

        localStorage.setItem("weightArr", JSON.stringify(touchArr));


        //dispatch({type:'SET_WEIGHT_CLASSIFICATION',data:touchArr});
        return touchArr;


        // setArr(touchArr);


    }, [props.arr])


    /*
        useEffect(() => {

            props.onSet(arr)
        }, [arr])*/


    return (
        <div>

            {/*<div style={{textAlign: 'initial', marginBottom: '6px'}}>Classification Of Weight</div>*/}
            {
                sampArr.map((vv, key) => {
                    return (
                        <div key={key}>

                            <Row gutter={{md: 10}}>
                                <Col style={{fontSize: 11}}><b>{vv.productName} : {vv.weight} kg </b> </Col>
                                {
                                    vv.childs.map((inv, invk) => {
                                        return (
                                            <Col style={{fontSize: 11, marginTop: 2}}
                                                 key={invk}> {inv.rawName} : {inv.weight} kg
                                                {invk != vv.childs.length - 1 ? ',' : ''}
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </div>
                    )
                })
            }

        </div>
    )
});
export default WeightClassification
