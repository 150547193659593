import axios from '../../../serverAxios'
import {apiUrl, errorObj} from '../../../settings'
import {getToken} from '../../../request'

export const AddAction = data => {
    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/challan`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const ListAction = data => {
    return new Promise(async next => {
        await axios
            .get(`${apiUrl}/challan`, {params: {...data}, ...getToken()})
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const UpdateAction = data => {
    return new Promise(async next => {
        await axios
            .put(`${apiUrl}/challan`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const NextChallanIdAction = data => {
    return new Promise(async next => {
        await axios
            .get(`${apiUrl}/nextChallanId`, {params: {...data}, ...getToken()})
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const GetAAction = id => {
    return new Promise(async next => {
        await axios
            .get(`${apiUrl}/challan/${id}`, {params: {}, ...getToken()})
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const ExportChallansAction = data => {
    return new Promise(async next => {
        await axios
            .get(`${apiUrl}/challan/exportChallan`, {
                params: {...data},
                ...getToken()
            })
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const PrintChallanAction = id => {
    return new Promise(async next => {
        await axios
            .get(`${apiUrl}/printChallan/${id}`, {
                ...getToken()
            })
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const ReportAction = data => {
    return new Promise(async next => {
        await axios
            .get(`${apiUrl}/challanPO/report`, {
                params: {...data},
                ...getToken()
            })
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}

export const SupplierReportAction = data => {
    return new Promise(async next => {
        await axios
            .get(`${apiUrl}/supplier/poReport`, {
                params: {...data},
                ...getToken()
            })
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const ExportReportAction = data => {
    return new Promise(async next => {
        await axios
            .get(`${apiUrl}/challanPO/exportReport`, {
                params: {...data},
                ...getToken()
            })
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const POWeightList = data => {
    return new Promise(async next => {
        await axios
            .get(`${apiUrl}/PO/weightList`, {params: {...data}, ...getToken()})
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const VerifyChallanItems = data => {
    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/challan/veriyItems`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}

export const DeleteChallanId = data => {

    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/challan/deleteProduct`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}

export const SearchSupplierItemsAction = data => {
    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/supplierOrder/getOrderedItems`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}


export const POWeightFilters = data => {
    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/getWeightFilters`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}


export const GetaPOReport = data => {

    return new Promise(async next => {
        await axios
            .get(`${apiUrl}/getAPOReport`, {params: {...data}, ...getToken()})
            .then(x => {

                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const GetaSupplierPOReport = data => {
    let hj = {}
    hj._id = data
    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/getaSupplierPOReport`, data, getToken())
            .then(x => {

                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const PrintaPOReport = data => {


    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/printaPOReport`, data, getToken())
            .then(x => {
                if (x && x.data && x.data.url) {
                    window.open(x.data.url)
                }

                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const PrintaSupplierPOReport = data => {


    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/printaSupplierPOReport`, data, getToken())
            .then(x => {
                if (x && x.data && x.data.url) {
                    window.open(x.data.url)
                }
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}
export const POListAccToSupplierId = data => {
    return new Promise(async next => {
        await axios
            .post(`${apiUrl}/toSupplierId/purchaseOrderList`, data, getToken())
            .then(x => {
                return next(x.data)
            })
            .catch(error => {
                return next({...errorObj, message: error})
            })
    })
}

export const itemBillingCompleteAction = data => {
    return new Promise(async next => {
        await axios
          .post(`${apiUrl}/challan/itemBillingCompete`, data, getToken())
          .then(x => {
              return next(x.data)
          })
          .catch(error => {
              return next({ ...errorObj, message: error })
          })
    })
}


export const EwayBillGenerateAction = id => {
    return new Promise(async next => {
        await axios
          .get(`${apiUrl}/ewaybill/generate/${id}`, {
              ...getToken()
          })
          .then(x => {
              return next(x.data)
          })
          .catch(error => {
              return next({ ...errorObj, message: error.message })
          })
    })
}

export const EwayBillCancelAction = data => {
    return new Promise(async next => {
        await axios
          .post(`${apiUrl}/ewaybill/cancel`, data,{
              ...getToken()
          })
          .then(x => {
              return next(x.data)
          })
          .catch(error => {
              return next({ ...errorObj, message: error.message })
          })
    })
}

export const DeleteChallan = data => {
    return new Promise(async (next) => {
        
        axios.delete(`${apiUrl}/challan/${data._id}`, getToken())
        .then((x) => {
            next(x.data)
        })
        .catch((x) => {
            next({...errorObj, message: "Error Occur On Delete Challan"})
        })
    })
}
