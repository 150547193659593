import React, {useState, useRef, useEffect} from 'react'
import {Col, Input, InputNumber, Row, Select, Table, Tag,} from 'antd'
import 'react-quill/dist/quill.snow.css'
import _ from 'lodash'
import {useSelector} from "react-redux";
import styles from "./styles.less";
import {v4 as uuidv4} from 'uuid'
import {TableComp} from 'sz-react-utils4'


const ChallanWeight = props => {


    const {challanItems} = useSelector((state) => {
        return {
            challanItems: state.challan.challanItems
        }
    });
    const [weightArr, setArr] = useState([])

    useEffect(() => {


        if (props.editValue && props.editValue.length) {


            setArr(props.editValue)
        }
    }, [props.editValue])

    useEffect(() => {


        let clonedArr = _.cloneDeep(challanItems);


        clonedArr = clonedArr.map((v) => {


            if (v.type == 'Raw Material') {
                v.weight = v.qty;
            } else {
                v.weight = null;
            }


            // challan has poId not poDocId, challan model cannot be fixed at this point of time.
            /*if(!v.poDocId && v.poId){
                v.poDocId=v.poId;
            }*/


            return v;
        })
        clonedArr = _.uniqBy(clonedArr, (elem) => {
            return [elem.productId, elem.poDocId].join()
        })


        // clonedArr=_.orderBy(clonedArr, ['type']['desc']);

        setArr(clonedArr);


    }, [challanItems])


    useEffect(() => {

        let totalWeight = _.sumBy(weightArr, (item) => item.weight ? parseFloat(item.weight) : 0);
        totalWeight = parseFloat(parseFloat(totalWeight).toFixed(2));
        props.setWeight({
            totalWeight,
            weightArr
        })


    }, [weightArr])


    const refColumns = [


        {
            title: 'Product/Raw Material',
            dataIndex: 'productName',
            key: 'productName',


        },
        {
            title: 'Code',
            dataIndex: 'productCode',
            key: 'productCode',



        },
        {
            title: 'PO Number',
            dataIndex: 'purchaseOrderNumber',
            key: 'purchaseOrderNumber',



        },
        {
            title: 'Type',
            key: 'type',

            render: (val, record) => {
                if (record.type == 'Product') {
                    return <Tag color={'green'}>Product</Tag>
                } else {
                    return <Tag color={'purple'}>Raw Material</Tag>
                }
            }
        },


        {
            title: 'Weight',
            key: 'weight',
            dataIndex: 'weight',
            width: 130,
            render: (val, record) => {
                if (record.type == 'Raw Material') {
                    return val;
                }

                return (
                    <Input type={'number'} className={styles.inputNumber} value={val} onChange={(e) => {
                        let tempArr = _.clone(weightArr);
                        let findIn = _.find(tempArr, (v) => {
                            return (v.poDocId == record.poDocId && v.variantId == record.variantId)
                        })
                        if (findIn) {
                            findIn.weight = e.target.value;
                        }


                        setArr(tempArr);



                    }}/>
                )
            }


        },


    ]


    return (
        <div>


            {weightArr.length ? <Row gutter={8}>


                <Col span={24}>
                    {<TableComp
                        bordered={true}
                        // rowKey={x => x.uuid}
                        columns={refColumns}
                        dataSource={weightArr || []}
                        pagination={false}
                    />}

                </Col>
            </Row> : ''}
        </div>
    )
}
export default ChallanWeight
