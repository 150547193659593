import {Resizable} from "react-resizable";
import React from "react";

export default {}

export const pageTitle = 'Meghna International LLP'
export const subTitle = 'Powered by Scizers Technologies'
//export const apiUrl = `http://carsd2c.scizers.com`
// export const apiUrl = `http://medmatrix.wss.io`
// export let apiUrl = `http://localhost:8066`
export let apiUrl = "https://meghna-r.scizers.com"
if (process.env.NODE_ENV == 'production') {
    apiUrl = 'https://meghna-r.scizers.com'
}
console.log("done")
export const errorObj = {error: true, type: 'error', success: false}
export const successObj = {error: false, type: 'success', success: true}
export const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 8},
        md: {span: 8}
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
        md: {span: 12}
    }
}
export const submitFormLayout = {
    wrapperCol: {
        xs: {span: 24, offset: 0},
        sm: {span: 10, offset: 7},
        md: {span: 12, offset: 8}
    }
}
export const ResizableTitle = (props) => {
    const {onResize, width, ...restProps} = props;
    if (!width) {
        return <th {...restProps} />;
    }
    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                />
            }
            onResize={onResize}
            draggableOpts={{
                enableUserSelectHack: false,
            }}

        >
            <th {...restProps} />
        </Resizable>
    );
};
export const taxRateSlab = [
    {value: 0, label: '0 %'},
    {value: 5, label: '5 %'},
    {value: 12, label: '12 %'},
    {value: 18, label: '18 %'},
    {value: 28, label: '28 %'},
];

