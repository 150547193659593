import axios from '../../../serverAxios'
import { apiUrl, errorObj } from '../../../settings'
import { getToken } from '../../../request'

export const AddJobTypeAtion = data => {
  return new Promise(async next => {
    await axios
      .post(`${apiUrl}/jobType`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const ListJobTypeAction = data => {
  return new Promise(async next => {
    await axios
      .get(`${apiUrl}/jobType`, { params: { ...data }, ...getToken() })
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const UpdateJobTypeAction = data => {
  return new Promise(async next => {
    await axios
      .put(`${apiUrl}/jobType`, data, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const DeleteJobType = data => {
  return new Promise(async next => {
    await axios
      .delete(`${apiUrl}/jobType/` + data._id, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

