import React, {useEffect, useState} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Button,
    Input,
    DatePicker,
    Tag,
    Card,
    Divider,
    Select,
    Spin,
    Row,
    Col,
    Popconfirm,
    Modal,
    Typography
} from 'antd'
import 'react-quill/dist/quill.snow.css'
import {
    PrinterOutlined,
    SaveOutlined,
    TableOutlined
} from '@ant-design/icons'
import {notification} from 'antd/lib/index'

import _ from 'lodash'
import moment from 'moment'
import styles from '../../challan/view/styles.less'

import {GetSanitizedAttribute} from '../../attribute/action'
import {TableComp} from 'sz-react-utils4'
import {dateFormat} from '../../../request'
import SearchSupplier from '../../../components/search/supplier'
import SearchPo from '../../../components/search/po'
import {GetPurchaseOrderAction} from '../../purchaseOrder/action'
import {addSupplierOrderAction, PrintSupplierOrder} from '../actions'
import {NextChallanIdAction} from "../actions";
import WeightClassification from "../../../components/weight/weightClassification";
import {ResizableTitle} from "../../../settings";
import {ReturnItemAmount} from '../../../components/_utils/utils';
import {SearchSupplierByName} from "../../supplier/action";
import {ListJobTypeAction} from '../../jobType/action'
let rateUnit = 'meter';
let proArr = []

let tempProds = []
const {TextArea} = Input
const {Text} = Typography
const purposeArr = [
    {name: 'Job Work', value: 'jobWork'},
    {name: 'Purchase Order', value: 'purchaseOrder'}
]
const AddSupplierOrder = props => {
    const [spinLoader, setSpinLoader] = useState(true)
    const [showPreview, setShowPreview] = useState(false)
    const [loader, setLoader] = useState(false)
    const [id, setId] = useState(null)
    let [arr, setArr] = useState([])
    const [choosenArr, setChoosen] = useState([])
    const [supplierList, setSupplier] = useState([])
    // const [jobList, setJobList] = useState([])
    const [tableKey, setTableKey] = useState()

    const [obj, setObj] = useState({
        supplierId: null,
        supplierName: '',
        poDocId: null,
        date: moment(),
        purchaseOrderNumber: null,
        totalApproxWeight: 0,
        purpose: null,
        rateUnit: rateUnit
        //weightClassification: []
    })

    const previewColss = [
        {
            title: 'Product',
            key: 'productName',
            dataKey: 'productName',
            width: 250,

        },
        {
            title: 'Code',
            key: 'productCode',
            dataKey: 'productCode',
            width: 100,
        },
        {
            title: 'Order Qty',
            key: 'orderQty',
            width: 100,
            render: val => {
                return <Tag color={'green'}>{val}</Tag>
            }
        },
        {
            title: 'Rate',
            key: 'rate',
            width: 100,
        },
        {
            title: 'Amount',
            key: 'itemAmount',
            width: 100,
            render: (val, record) => {
                return <div>{ReturnItemAmount(record, rateUnit)}</div>
            }
        },
        {
            title: 'Size',
            key: 'size',
            width: 100,
        },
        {
            title: 'Colour',
            key: 'colour',
            width: 100,
        },
        {
            title: 'Approx Weight',
            key: 'approxWeight',
            width: 100,
            render: (val, record) => {
                return <div>{returnApproxWeight(record)}</div>
            }
        }

    ]

    let [cols, setCols] = useState([
        {
            title: 'Product',
            key: 'productName',
            dataKey: 'productName',
            width: 250,
            // fixed: 'left',
            searchTextName: 'ProductName',
            onFilter: (value, record) => record.productName.indexOf(value.toUpperCase()) === 0
        },
        {
            title: 'Code',
            key: 'productCode',
            dataKey: 'productCode',
            width: 100,
            //  fixed: 'left',
            searchTextName: 'ProductCode',
            onFilter: (value, record) => record.productCode.indexOf(value.toUpperCase()) === 0
        }
    ])
    // let [previewCols, setPreviewCols] = useState([])

    const {Option} = Select

    useEffect(() => {
        // fetchInfo()
        fetchNextId();
        fetchAttributes()
        fetchSupplier()
    }, [])
    const fetchSupplier = async () => {
        let {data} = await SearchSupplierByName({isMeghnaOffice: true})
        setSupplier(data)
    }
    useEffect(
        () => {
            proArr = arr
        },
        [arr]
    )

    useEffect(
        () => {
            calTotalQty()
        },
        [choosenArr]
    )


    const fetchNextId = async () => {
        let {data} = await NextChallanIdAction()
        if (data) {
            setObj({
                ...obj,
                orderNumber: data
            })
        }
    }

    const returnApproxWeight = item => {
        if (item.orderQty && item.weight) {
            item.weight = item.weight.replace('KG', '')
            item.weight = item.weight.replace('kg', '')
            item.weight = item.weight.trim()
            return parseFloat(parseFloat(parseFloat(item.orderQty) * parseFloat(item.weight)).toFixed(2))
        }
        return 0
    }


    const fetchAttributes = async () => {

        let {success, data} = await GetSanitizedAttribute({showInList: true})
        let {error: jobError, data: jobArr} = await ListJobTypeAction()

        /* if(!jobError){
             setJobList(jobArr);

         }*/
        data = _.reject(data, (v) => v.key == 'rate');

        if ((success, data)) {
            cols.push({
                title: 'Total Qty',
                key: 'qty',
                width: 100,
                dataKey: 'qty',
                render: val => {
                    return <Tag color={'geekblue'}>{val}</Tag>
                }
            })

            /* cols.push({
               title: 'Bom',
               key: 'bom',
               dataIndex: 'bom',
               render: (val, record) => {
                 return val ? val.length : 0
               }
             })*/
            cols.push({
                title: 'Ordered',
                key: 'ordered',
                width: 100,
                render: val => {
                    return <Text type={'success'}>{val}</Text>
                }
            })
            cols.push({
                title: 'Unordered',
                key: 'unordered',
                width: 100,
                render: val => {
                    return <Text type={'danger'}>{val}</Text>
                }
            })
            cols.push({

                title: 'Order Qty',
                key: 'orderQty',
                width: 100,
                render: (val, record) => {
                    return (
                        <Input
                            value={val}
                            type={'number'}
                            min={1}
                            onChange={e => {
                                record.orderQty = e.target.value;
                                record.type = 'Product';

                                let tempp = _.cloneDeep(tempProds)
                                let output = _.find(tempp, v => {
                                    return record._id == v._id
                                })
                                if (output) {
                                    output.orderQty = Math.abs(e.target.value)
                                } else {
                                    tempp.push(record);
                                }

                                tempp = _.reject(tempp, (v) => !v.orderQty);
                                tempProds = tempp
                                setChoosen(tempp)
                            }}

                        />
                    )
                }
            })
            cols.push({
                title: 'Rate',
                key: 'rate',
                width: 100,

                render: (val, record) => {
                    return (
                        <Input
                            value={val}
                            type={'number'}
                            min={1}
                            onChange={e => {
                                record.rate = e.target.value
                                let tempp = _.cloneDeep(tempProds)
                                let output = _.find(tempp, v => {
                                    return record._id == v._id
                                })

                                if (output) {
                                    output.rate = Math.abs(e.target.value)
                                    tempProds = tempp
                                    setChoosen(tempp)
                                }
                            }}
                        />
                    )
                }
            })
            cols.push({
                title: 'Amount',
                key: 'itemAmount',
                width: 100,
                render: (val, record) => {
                    return <div>{ReturnItemAmount(record, rateUnit)}</div>
                }
            })
            if (!jobError) {
                cols.push({
                    title: 'Extra Job',
                    key: 'jobId',
                    width: 100,
                    dataKey: 'jobId',
                    render: (val, record) => {
                        return <Select
                            style={{width: '150px'}} value={record.jobId} onChange={(e) => {
                            record.jobId = e;


                            let tempp = _.cloneDeep(tempProds)
                            let output = _.find(tempp, v => {
                                return record._id == v._id
                            })

                            if (output) {
                                output.jobId = e

                                let findIn = _.find(jobArr, (val) => {
                                    return val._id == e
                                })
                                if (findIn) {
                                    output.jobType = findIn.jobType;
                                    record.jobType = findIn.jobType;
                                }

                                tempProds = tempp
                                setChoosen(tempp)
                            }

                        }}
                        >
                            <Option value={null} key={0}>Choose</Option>
                            {
                                jobArr.map((v, index) => {
                                    return <Option value={v._id} key={v._id}>{v.jobType}</Option>
                                })
                            }
                        </Select>
                    }
                })
            }
            _.each(data, v => {
                delete v.filters
                delete v.searchTextName
                cols.push({
                    ...v,
                    width: 100
                })
            })
            cols.push({
                title: 'Approx Weight',
                key: 'approxWeight',
                width: 100,
                render: (val, record) => {
                    return <div>{returnApproxWeight(record)}</div>
                }
            })
            cols.push({
                title: 'Remove',
                key: 'key',
                fixed: 'right',
                width: 80,
                render: (val, record) => {
                    return (
                        <div>
                            <Popconfirm
                                title="Are you sure to remove?"
                                onConfirm={() => {
                                    proArr = _.reject(proArr, function (e) {
                                        return record._id == e._id
                                    })
                                    setArr(proArr)
                                }}
                                onCancel={() => {
                                }}
                                okText="Yes"
                                cancelText="No">
                                <a>Remove</a>
                            </Popconfirm>
                        </div>
                    )
                }
            })

            setCols(cols)

            // let tempCols = _.cloneDeep(cols)
            // tempCols.pop()
            // tempCols = _.reject(tempCols, v => {
            //     return v.key == 'orderQty'
            // })
            // tempCols.push({
            //     title: 'Order Qty',
            //     key: 'orderQty',
            //     width: 100,
            //     render: val => {
            //         return <Tag color={'green'}>{val}</Tag>
            //     }
            // })
            // setPreviewCols(tempCols)

            setSpinLoader(false)
        }
    }


    const searchPoItems = async () => {

        setArr([])
        setChoosen([])
        proArr = [];
        tempProds = []
        if (!obj.poDocId) {
            notification.error({message: 'Choose PO'})
            return
        }


        setLoader(true)
        let {success, data} = await GetPurchaseOrderAction({id: obj.poDocId, onlyPending: true})
        setLoader(false)

        if (success) {


            setArr(_.orderBy(data.arr, ['productName'], ['asc']))

            // let productFilter = _.uniq(_.map(data.arr, item => item.productName))
            // let codeFilter = _.uniq(_.map(data.arr, item => item.productCode))
            let sizeFilter = _.uniq(_.map(data.arr, item => item.size))
            let colorFilter = _.uniq(_.map(data.arr, item => item.colour))
            colorFilter = _.filter(colorFilter, (ii) => ii !== undefined)

            _.each(cols, v => {
                // if (v.key == 'productName') {
                //     v.filters = _.map(productFilter, x => {
                //         return {text: x, value: x}
                //     })
                // }
                // if (v.key == 'productCode') {
                //     v.filters = _.map(codeFilter, x => {
                //         return {text: x, value: x}
                //     })
                // }
                if (v.key == 'size') {
                    v.filters = _.map(sizeFilter, x => {
                        return {text: x, value: x}
                    })
                    v.onFilter = (value, record) =>
                        record && record.size && record.size.indexOf(value) === 0
                }
                if (v.key == 'colour') {
                    v.filters = _.map(colorFilter, x => {
                        return {text: x, value: x}
                    })
                    v.onFilter = (value, record) =>
                        record && record.colour && record.colour.indexOf(value) === 0
                }
            })
            setTableKey(Date.now())
            // setPreviewCols(cols)
        } else {
            setArr([])
        }
    }

    const calTotalQty = () => {
        let rQ = 0
        let pQ = 0
        let aW = 0
        _.each(choosenArr, v => {
            pQ += v.orderQty ? parseFloat(parseFloat(v.orderQty).toFixed(2)) : 0

            if (v.orderQty && v.weight) {
                aW += parseFloat(parseFloat(parseFloat(v.orderQty) * parseFloat(v.weight)).toFixed(2))
            }
        })

        setObj({
            ...obj,
            totalQty: rQ + pQ,
            totalApproxWeight: parseFloat(parseFloat(aW).toFixed(2))
        })
    }

    const saveOnly = async (supplierPrint) => {


        if (checkValidation() == 'error') {
            return
        }


        if (obj.purpose == 'jobWork') {
            let weightArr = await localStorage.getItem('weightArr');
            try {
                if (weightArr) {
                    weightArr = JSON.parse(weightArr);
                    obj.weightClassification = weightArr;
                }
            } catch (e) {

                notification.error({message: "Try again"})
                return;
            }

        } else {
            obj.weightClassification = [];
        }


        obj.orderType = 'Piece Order';
        let addressArray = []

        _.each(obj.addressIds, (item) => {
            let findIn = _.find(supplierList, (v) => {
                return v._id == item;
            })
            if (findIn) {
                let h = findIn.address && findIn.address[0]
                addressArray.push(h && h.address)
            }

        })


        obj.deliveryAddress = addressArray


        setLoader(true)

        let x = await addSupplierOrderAction({...obj, arr: choosenArr})

        if (!x.error) {

            notification.success({
                message: 'Supplier Order Added Successfully'
            })

            if (supplierPrint && x._id) {


                const data = await PrintSupplierOrder(x && x._id)

                if (data.success) {
                    window.open(data.url)
                }
            }

            setTimeout(() => {
                window.location.reload(true)
            }, 500)

            return
        } else {
            setLoader(false)
            notification.error({
                message: 'Error Saving',
                description: x.message
            })
        }
    }

    const checkValidation = () => {
        if (!obj.supplierId) {
            notification.error({message: 'Choose a supplier'})
            return 'error'
        }
        if (!obj.purchaseOrderNumber) {
            notification.error({message: 'Choose a PO'})
            return 'error'
        }
        if (!obj.purpose) {
            notification.error({message: 'Choose Purpose'})
            return 'error'
        }

        if (!choosenArr || !choosenArr.length) {
            notification.error({message: 'Choose Products'})
            return 'error'
        }

        let findIn = _.find(choosenArr, v => {
            return !v.orderQty
        })
        if (findIn) {
            notification.error({
                message: 'Required',
                description: `Enter quantity for ${findIn.productName}`
            })
            return 'error'
        }

        let findExceed = _.find(choosenArr, v => {
            return parseFloat(v.orderQty) > parseFloat(v.unordered)
        })
        if (findExceed) {
            notification.error({
                message: 'Required',
                description: `Order quantity of ${
                    findExceed.productName
                } cannot exceed total quantity (${findExceed.unordered}).`
            })
            return 'error'
        }

        return
    }

    const onPreview = () => {
        if (checkValidation() == 'error') {
            return
        }

        setShowPreview(true)
    }
    const handleResize = (index) => (_, {size}) => {
        const newColumnss = [...cols];
        newColumnss[index] = {...newColumnss[index], width: size.width};
        setCols(newColumnss);
    };

    const mergeColumns = cols.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width || 150,
            onResize: handleResize(index),
        }),
    }));

    return (
        <PageHeaderWrapper title={`${id ? 'Edit' : 'Add'} Piece Order`}>
            <Spin spinning={spinLoader}>
                <Card>
                    <Row gutter={6} style={{marginBottom: 10}}>

                        <Col>
                            <b>Choose Supplier</b>
                            <SearchSupplier
                                onSelect={e => {
                                    setObj({
                                        ...obj,
                                        supplierId: e._id,
                                        supplierName: e.name
                                    })
                                }}
                            />
                        </Col>
                        <Col span={4}>
                            <b>Choose Purpose</b><br></br>
                            <Select style={{width: '150px'}} value={obj.purpose} onChange={(e) => {
                                setObj({
                                    ...obj,
                                    purpose: e
                                })
                            }}>
                                {
                                    purposeArr.map((val, ind) => {
                                        return <Option value={val.value} key={ind}>{val.name}</Option>
                                    })
                                }
                            </Select>
                        </Col>
                        <Col>
                            <b>Choose PO</b>
                            <br/>
                            <SearchPo
                                onSelect={e => {
                                    setObj({
                                        ...obj,
                                        poDocId: e._id,
                                        purchaseOrderNumber: e.purchaseOrderNumber
                                    })
                                }}
                            />
                        </Col>
                        <Col>
                            <br/>
                            <Button onClick={() => searchPoItems()} type={'primary'}>
                                Search PO Items
                            </Button>
                        </Col>


                    </Row>

                    <Row gutter={{md: 10}}>
                        <Col>
                            <b>Order Date</b>
                            <br/>
                            <DatePicker
                                format={dateFormat}
                                value={obj.date}
                                onChange={e => {
                                    setObj({
                                        ...obj,
                                        date: e
                                    })
                                }}
                            />
                        </Col>
                        <Col>
                            <b>Order NO</b>
                            <br/>
                            <div>
                                <Input value={obj.orderNumber} disabled={true}/></div>
                        </Col>
                        <Col
                        >
                            <b>Due Date</b>
                            <br/>
                            <DatePicker
                                onChange={(date, dateString) => {
                                    setObj({
                                        ...obj,
                                        dueDate: dateString
                                    })

                                }}
                            />

                        </Col>
                        <Col
                        >
                            <b>Rate Per</b>
                            <br/>
                            <Select style={{width: 200}} value={obj.rateUnit} onChange={(e) => {
                                rateUnit = e;
                                setObj({
                                    ...obj,
                                    rateUnit: e
                                })
                            }}>
                                {/*<Option value={'cm'}>cm</Option>*/}
                                <Option value={'meter'}>Square Meter</Option>
                                <Option value={'foot'}>Square Foot</Option>
                            </Select>
                        </Col>
                        <Col span={4}>
                            <b>Delivery Address</b><br></br>
                            <Select dropdownMatchSelectWidth={false} style={{width: '150px'}}
                                    value={obj.addressIds}
                                    mode={'multiple'} onChange={(e) => {
                                setObj({
                                    ...obj,
                                    addressIds: e
                                })


                            }}>
                                {
                                    supplierList.map((val, ind) => {
                                        return <Option value={val._id} key={ind}>{val.name}</Option>
                                    })
                                }
                            </Select>
                        </Col>
                    </Row>

                </Card>


                <Card title={`Collections (${arr.length})`}>
                    {!spinLoader ? (
                        <div>
                            <TableComp
                                dataSource={arr}
                                key={tableKey}
                                extraProps={{
                                    scroll: {x: true}, components: {
                                        header: {
                                            cell: ResizableTitle,
                                        },
                                    }
                                }}
                                loading={loader}

                                pagination={{
                                    defaultPageSize: 500,
                                    pageSizeOptions: ['100', '250', '500', '1000'],
                                    showSizeChanger: true
                                }}
                                columns={mergeColumns}
                            />
                        </div>
                    ) : null}
                </Card>
                <Card>
                    <Row className={styles.noteArea}>
                        <Col>
                            <div>
                                <b>Note if any</b>
                            </div>

                            <TextArea
                                style={{width: 300}}
                                onChange={e => {
                                    setObj({
                                        ...obj,
                                        notes: e.target.value
                                    })
                                }}
                                rows={2}
                            />
                        </Col>

                        <Col style={{flex: 1}}>
                            <Row gutter={{md: 10}}>
                                <Col className={styles.totalQty}>
                                    <b>Total Qty : {obj.totalQty} </b>
                                </Col>
                                <Col className={styles.totalQty}>
                                    <b>Total Approximate Weight : {obj.totalApproxWeight} KG </b>
                                </Col>
                            </Row>
                            {
                                obj.purpose == 'jobWork' ? <div className={styles.classification}>
                                    <WeightClassification arr={choosenArr}
                                        /*onSet={(e) => {
                                            setObj({
                                                ...obj,
                                                weightClassification: e
                                            })
                                        }}*/
                                    />
                                </div> : null
                            }

                        </Col>
                    </Row>


                </Card>
                <Card>
                    <Row className={styles.btnctr}>
                        <Col style={{marginRight: 10}}>
                            <Button
                                disabled={loader}
                                onClick={() => {
                                    onPreview()
                                }}
                                type={'secondary'}>
                                <TableOutlined/> Preview Order
                            </Button>
                        </Col>

                        <Col style={{marginRight: 10}}>
                            <Popconfirm
                                title="Preview Checked! want to save order?"
                                onConfirm={() => {
                                    saveOnly(false)
                                }}
                                onCancel={() => {
                                }}

                                okText="Yes Save Order"
                                cancelText="No">
                                <Button disabled={loader} onClick={() => {
                                }} type={'primary'}><SaveOutlined/> Save Only</Button>
                            </Popconfirm>
                        </Col>
                        <Col>
                            <Popconfirm
                                title="Preview Checked! want to save order?"
                                onConfirm={() => {
                                    saveOnly(true)
                                }}
                                onCancel={() => {

                                }}
                                okText="Yes Save Order"
                                cancelText="No">
                                <Button disabled={loader} onClick={() => {
                                }} type={'primary'}><PrinterOutlined/> Save & Print</Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                </Card>
            </Spin>

            <Modal
                title="Preview Order Details"
                centered
                visible={showPreview}
                onOk={() => setShowPreview(false)}
                onCancel={() => setShowPreview(false)}
                width={1400}>
                <div>
                    <Row style={{marginBottom: 10}}>
                        <Col span={4}>
                            <label>Supplier</label>
                            <div>{obj.supplierName}</div>
                        </Col>
                        <Col span={4}>
                            <label>Purchase Order</label>
                            <div>{obj.purchaseOrderNumber}</div>
                        </Col>
                        <Col span={4}>
                            <label>Total Items</label>
                            <div>{obj.totalQty}</div>
                        </Col>
                    </Row>
                    <Divider/>
                    <TableComp
                        dataSource={choosenArr}
                        loading={loader}
                        extraProps={{scroll: {x: true}}}
                        pagination={{
                            defaultPageSize: 500,
                            pageSizeOptions: ['100', '250', '500', '1000'],
                            showSizeChanger: true
                        }}
                        columns={previewColss}
                    />
                </div>
            </Modal>

        </PageHeaderWrapper>
    )
}
export default AddSupplierOrder
