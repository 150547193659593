import React, {useEffect, useState, useRef} from 'react'
import {

    Button, Input, notification,
    Card,
    Spin, Row, Col, Drawer, Select
} from 'antd'
import 'react-quill/dist/quill.snow.css'

import styles from './styles.less'

import _ from 'lodash'
import {SearchChallanItemsAction} from '../action'
import {useDispatch, useSelector} from "react-redux";
import {TableComp} from 'sz-react-utils4'
import {ListUnitAction} from '../../unit/action'

let tempProds = []
let unitArr = []
const {Option} = Select
const SearchProduct = (props) => {

    const [spinLoader, setSpinLoader] = useState(false)
    const [filter, setFilter] = useState({
        productId: null,
        poDocId: null,
        orderDocId: null
    })
    const [choosenArr, setChoosen] = useState([])
    const [arr, setArr] = useState([])
    const [tableKey, setTableKey] = useState()
    const [poArr, setPoArr] = useState([]);
    const [proArr, setProArr] = useState([]);
    const [orderArr, setOrderArr] = useState([]);
    const [sizeArr, setSizeArr] = useState([]);
    const [colourArr, setColourArr] = useState([]);
    let [cols, setCols] = useState([])
    const tableRef = useRef()


    const {challanArr} = useSelector((state) => {
        return {
            challanArr: state.challan.challanSearchItems
        }
    })


    const dispatch = useDispatch();
    useEffect(() => {
        setCols([
            {
                title: 'PO',
                key: 'purchaseOrderNumber',
                dataKey: 'purchaseOrderNumber',
                filters:
                    poArr &&
                    poArr.map(vv => {
                        return {
                            text: vv.purchaseOrderNumber,
                            value: vv.purchaseOrderNumber
                        }
                    }),
                    onFilter :(value,record)  =>{ 
                       
                       return record.purchaseOrderNumber === value},
                    //  (value, record) => record.value === value,
                fixed: 'left'
            },
            {
                title: 'Order No',
                key: 'orderNumber',
                dataKey: 'orderNumber',
                filters:
                    orderArr &&
                    orderArr.map(vv => {
                        
                        return {
                            text: vv.orderNumber,
                            value: vv.orderNumber
                        }
                    }),
                    onFilter :(value,record)  =>{ 
                       
                        return record.orderNumber === value},

            },
            {
                title: 'Product',
                key: 'productName',
                dataKey: 'productName',
                filters:
                    proArr &&
                    proArr.map(vv => {
                      
                        return {
                            text: vv.name,
                            value: vv.name
                        }
                    }),
                    onFilter :(value,record)  => { 
                       
                        return record.productName === value},


            },
            {
                title: 'Code',
                key: 'productCode',
                dataKey: 'productCode',
                width: 100,
            },

            {
                title: 'Challan Qty',
                key: 'challanQty',
                dataKey: 'challanQty',

            },
            {
                title: 'Reject Qty',
                key: 'qty',
                dataKey: 'qty',
                render: (val, record) => {
                    return <Row gutter={{md: 10}}>
                        <Col><Input type={'number'}
                                    className={styles.inputNumber}
                                    value={record.qty} style={{width: 100}} min={0} onChange={(e) => {

                            record.qty = e.target.value
                            let tempp = _.clone(tempProds)
                            let output = _.find(tempp, (v) => {
                                return (record._id == v._id)
                            })

                            if (output) {
                                output.qty = e.target.value
                            } else {
                                record.qty = e.target.value;
                                tempp.push(record)
                            }

                            tempp = _.reject(tempp, (v) => !v.qty);
                            tempProds = tempp;



                            // dispatch({type: 'SET_CHALLAN_SEARCH_ITEMS', data: tempProds})
                            setChoosen(tempp)

                        }}/>
                        </Col>

                    </Row>
                }


            },
            {
                title: 'Size',
                key: 'size',
                dataKey: 'size',
                render: (val, record) => {
                    return (
                        <div>{val} {record.sizeUnit}</div>
                    )
                },
                filters:
                    sizeArr &&
                    sizeArr.map(vv => {
                        return {
                            text: `${vv.size}`,
                            value: vv.size
                        }
                    })

            }, {
                title: 'Colour',
                key: 'colour',
                dataKey: 'colour',
                filters:
                    colourArr &&
                    colourArr.map(vv => {
                        return {
                            text: `${vv}`,
                            value: vv
                        }
                    })
            },
            {
                title: 'Weight',
                key: 'weight',
                dataKey: 'weight',
                render: (val, record) => {
                    return (
                        <div>{val} {record.weightUnit}</div>
                    )
                }
            }

        ])
    }, [poArr, proArr, sizeArr, colourArr, orderArr])

    useEffect(() => {

        if (challanArr && challanArr.length) {

            setChoosen(challanArr);
        }

        fetchUnits();

    }, [])


    const fetchUnits = async () => {
        let {data} = await ListUnitAction({
            results: 1000
        })
        if (data) {
            unitArr = data
        }
    }
    useEffect(() => {
        if (filter && tableRef.current) {
            tableRef.current.reload()
        }

    }, [filter])


    const apiRequest = (params) => {
        return new Promise(async (resolve) => {

           
            if (!props.fromSupplierId) {

                return resolve({data: []});
            }

            const {productId, poDocId, orderDocId} = filter;

            if (poDocId) {
                params.poDocId = filter.poDocId
            }
            if (orderDocId) {
                params.orderDocId = filter.orderDocId
            }
            if (productId) {
                params.productId = filter.productId
            }
            if (props.fromSupplierId) {
                params.fromSupplierId = props.fromSupplierId;
            }
            if (props.toSupplierId) {
                params.toSupplierId = props.toSupplierId;
            }
            if (filter.size) {
                params.size = filter.size;
            }
            if (filter.colour) {
                params.colour = filter.colour;
            }
            params.orderType = 'Piece Order';

            if (props.orderType) params.orderType = props.orderType;

            let x = await SearchChallanItemsAction({
                ...params
                // regExFilters: ['name']
            })

            if (x) {
                if (x.success) {
                  
                    setPoArr(x.poArr);
                    setProArr(_.uniqBy(x.proArr, v => v.name));
                    setSizeArr(_.uniqBy(x.sizeArr, v => v.size));
                    setColourArr(x.colourArr);
                    setOrderArr(_.uniqBy(x.orderArr, v => v.orderNumber));
                }

                if (x.data) {
                    x.data.map((v) => {
                        v.challanQty = _.clone(v.qty);
                        v.qty = null;
                        let findIn = _.find(challanArr, (record) => {
                            return (
                                record._id == v._id
                            )

                        })
                        if (findIn) {
                            v.qty = findIn.qty;
                        }
                        return v;
                    })
                    setArr(x.data)

                }
                return resolve(x)


            }


        })
    }


    return (
        <Drawer title={`    ${props.supplierName}`} width={'85%'} placement="right"
                onClose={() => {
                    // dispatch({type: 'SET_CHALLAN_SEARCH_ITEMS', data: []});
                    setChoosen([]);
                    props.onClose();
                }
                } visible={true}>
            <Spin spinning={spinLoader}>
                {/* <Card bordered={true}>

                    <Row gutter={{md: 10}}>


                        {
                            poArr && poArr.length ? <Col style={{flex: 1}}>
                                <div><label>Choose PO</label></div>
                                <Select allowClear={true} style={{width: '100%'}} onChange={(e) => {
                                    setFilter({
                                        ...filter,
                                        poDocId: e
                                    })
                                }}>{poArr.map((v, key) => {
                                    return <Option value={v._id} key={v._id}>{v.purchaseOrderNumber}</Option>
                                })}</Select></Col> : null

                        }
                        {
                            orderArr && orderArr.length ? <Col style={{flex: 1}}>
                                <div><label>Choose Order</label></div>
                                <Select allowClear={true} style={{width: '100%'}}
                                        onChange={(e) => {

                                            setFilter({
                                                ...filter,
                                                orderDocId: e
                                            })
                                        }}
                                >{orderArr.map((v, key) => {
                                    return <Option value={v._id} key={v._id}>{v.orderNumber}</Option>
                                })}</Select></Col> : null

                        }
                        {
                            proArr && proArr.length ? <Col style={{flex: 1}}>
                                <div><label>Choose Product</label></div>
                                <Select style={{width: '100%'}} allowClear={true} onChange={(e) => {
                                    setFilter({
                                        ...filter,
                                        productId: e
                                    })
                                }}>{proArr.map((v, key) => {
                                    return <Option value={v._id} key={v._id}>{v.name}</Option>
                                })}</Select></Col> : null

                        }
                        {
                            sizeArr && sizeArr.length ? <Col style={{flex: 1}}>
                                <div><label>Choose Size</label></div>
                                <Select onChange={(e) => {
                                    setFilter({
                                        ...filter,
                                        size: e
                                    })


                                }} style={{width: '100%'}} allowClear={true}>{sizeArr.map((v, key) => {
                                    return <Option value={v.size} key={v}>{v.size} {v.sizeUnit}</Option>
                                })}</Select></Col> : null

                        }

                        {
                            colourArr && colourArr.length ? <Col style={{flex: 1}}>
                                <div><label>Choose Colour</label></div>
                                <Select style={{width: '100%'}} allowClear={true} onChange={(e) => {
                                    setFilter({
                                        ...filter,
                                        colour: e
                                    })
                                }}>{colourArr.map((v, key) => {
                                    return <Option value={v} key={v}>{v}</Option>
                                })}</Select></Col> : null

                        }


                        <Col className={styles.btnCdtr}>

                        </Col>

                    </Row>


                </Card>*/}


                <Card extra={<div>
                               <span className={styles.choosenCount}>
                                   {choosenArr && choosenArr.length ? `${choosenArr.length} Products Selected.` : null} </span>
                    {choosenArr && choosenArr.length ?
                        <Button className={styles.okbtn} onClick={(e) => {
                            if (choosenArr && choosenArr.length) {


                                let findIn = _.find(choosenArr, (v) => {
                                    return v.qty > v.challanQty;
                                })

                                if (findIn) {
                                    notification.warn({
                                        message: `Reject qty of ${findIn.productName} ${findIn.size} cannot exceed challan qty.`
                                    })
                                    return;
                                }


                                props.onSeleced(choosenArr)
                                //   dispatch({type: 'SET_CHALLAN_SEARCH_ITEMS', data: []});
                                setChoosen([]);
                                props.onClose()
                                tempProds = []


                            }
                        }}>Ok</Button> : null}
                </div>}>
                    {
                        !spinLoader ?
                            <TableComp
                                ref={tableRef}
                                key={tableKey}

                                pagination={{

                                    defaultPageSize: 500,
                                    pageSizeOptions: ['100', '250', '500', '1000'],
                                    showSizeChanger: true

                                }}
                                rowKey={x => x._id}
                                apiRequest={apiRequest}
                                extraProps={{scroll: {x: true}}}
                                columns={cols}/> : null}
                </Card>
            </Spin>
        </Drawer>

    )

}
export default SearchProduct




