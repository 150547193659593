import axios from 'axios'
import store from './store'
import { apiUrl } from './settings'

export const API_URL = apiUrl

let authAxios = axios.create({
  baseURL: apiUrl
})

export const dateFormat = 'DD-MMM-YYYY'
export const getToken = () => {
  return {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
}


class Request {
  error = err => {
    try {
      if (err.response.status === 401||err.response.status === 404 ) {
        localStorage.clear()
      }
    } catch (e) {}
  }

  constructor() {}

  login(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/login', data)
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllUser(data) {
    return new Promise(next => {
      authAxios
        .get('/user', { params: { ...data }, ...getToken() })
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addUser(data) {
    return new Promise(next => {
      authAxios
        .post('/user', { ...data }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  updateUser(data) {
    return new Promise(next => {
      authAxios
        .put('/user/' + data._id, data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
}

export default new Request()
