import React, {useEffect, useState} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Button,
    Input,
    DatePicker,
    Tag,
    Card,
    Select,
    Spin,
    Row,
    Col,
    Popconfirm,
    AutoComplete,
    Typography,
    Upload, Modal, Divider
} from 'antd'
import 'react-quill/dist/quill.snow.css'
import {

    SearchOutlined,
    SaveOutlined,
    PrinterOutlined,
    UserOutlined,
    PhoneOutlined,
    MailOutlined,
    UploadOutlined,
    EnvironmentOutlined, TableOutlined,
} from '@ant-design/icons'
import {notification} from 'antd/lib/index'
import styles from './styles.less'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import SearchProduct from './searchProduct'
import {TableComp} from 'sz-react-utils4'
import SearchSupplier from '../../../components/search/supplier'
import {API_URL, dateFormat} from '../../../request'
import {UpdateAction, GetAAction, PrintRejectionAction} from '../action'
import {goBack} from 'connected-react-router'

import {v4 as uuidv4} from 'uuid'
import ChallanWeight from '../../challan/view/chllanWeightSection'

let proArr = []

const {TextArea} = Input
const {Option} = Select
const {Text} = Typography

const EditRejection = props => {
    const [spinLoader, setSpinLoader] = useState(false)
    let [showSearch, setSearch] = useState(false)
    let [fromAddress, setFromAddress] = useState([])
    let [toAddress, setToAddress] = useState([])
    let [fromAddressKey, setFromAddressKey] = useState()
    let [toAddressKey, setToAddressKey] = useState()
    const [key, setKey] = useState(1)
    let [arr, setArr] = useState([])
    let [weightArr, setWeightArr] = useState([])
    const [showPreview, setShowPreview] = useState(false)

    const [obj, setObj] = useState({
        fromSupplierId: null,
        toSupplierId: null,
        fromSupplierName: String,
        toSupplierName: String,
        rejectionDate: moment(),
        challanId: null,
        supplierChallanNumber: null,
        challanDocId: null,
        rejectionNumber: null,
        notes: 0,
        totalRawQty: 0,
        totalProductQty: 0,
        totalQty: 0,
        vehicleNumber: null,
        vehicleName: null,
        _id: null,
        fromAddress: {
            name: null,
            mobile: null,
            email: null,
            state: null,
            city: null,
            address: null,
            pinCode: null
        },
        toAddress: {
            name: null,
            mobile: null,
            email: null,
            state: null,
            city: null,
            address: null,
            pinCode: null
        },
        reason: null,
        totalWeight: null
    })
    let [cols, setCols] = useState([
        {
            title: 'Product',
            key: 'productName',
            dataKey: 'productName',
            width: 200,

        },
        {
            title: 'Code',
            key: 'productCode',
            dataKey: 'productCode',
            width: 150,

        },
        {
            title: 'PO',
            key: 'purchaseOrderNumber',
            width: 100,
            dataKey: 'purchaseOrderNumber',

        },
        {
            title: 'Reject Qty',
            key: 'qty',
            width: 200,
            dataKey: 'qty',
            render: (val, record) => {
                return (
                    <Row>
                        <Col>
                            <Input
                                className={styles.inputNumber}
                                value={val}
                                style={{width: 100}}
                                type={'number'}
                                min={1}
                                onChange={e => {
                                    let tempArr = _.cloneDeep(proArr)
                                    let findIn = _.find(tempArr, (v, k) => {
                                        //return v._id == record._id
                                        return (v.poDocId == record.poDocId && v.variantId == record.variantId)
                                    })
                                    if (findIn) {
                                        findIn.qty = e.target.value
                                    }
                                    proArr = tempArr
                                    setArr(tempArr)
                                }}
                            />
                        </Col>

                    </Row>
                )
            }
        },
        {
            title: 'Size', key: 'size', dataKey: 'size', render: (val, record) => {
                return (
                    <div>{val} {record.sizeUnit}
                    </div>
                )
            }
        },
        {title: 'Colour', key: 'colour', dataKey: 'colour'},
        {
            title: 'Weight',
            key: 'weight',
            dataKey: 'weight',
            render: (val, record) => {
                return (
                    <div>{val} {record.weightUnit}
                    </div>
                )
            }

        },
        {
            title: 'Approx Weight',
            key: 'approxWeight',

            render: (val, record) => {
                return <div>{returnApproxWeight(record)}</div>
            }
        },
        {
            title: 'Remove',
            key: 'key',
            fixed: 'right',
            render: (val, record) => {
                return (
                    <div>
                        <Popconfirm
                            title="Are you sure to remove?"
                            onConfirm={() => {
                                proArr = _.reject(proArr, function (e) {
                                    return record._id == e._id
                                    //return (record.poDocId == e.poDocId && record.variantId == e.variantId)
                                })
                                setArr(proArr)
                            }}
                            onCancel={() => {
                            }}
                            okText="Yes"
                            cancelText="No">
                            <a>Remove</a>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ])

    const dispatch = useDispatch()


    useEffect(() => {

        fetchInfo()


    }, [])


    const fetchInfo = async () => {
        let searchParams = new URLSearchParams(window.location.search)
        let id = searchParams.get('id')
        if (id) {
            let {success, data} = await GetAAction(id)


            if (success) {

                setObj({
                    fromSupplierId: data.fromSupplierId,
                    toSupplierId: data.toSupplierId,
                    fromSupplierName: data.fromSupplierName,
                    toSupplierName: data.toSupplierName,
                    rejectionDate: moment(data.rejectionDate),
                    notes: data.notes,
                    totalRawQty: data.totalRawQty,
                    totalProductQty: data.totalProductQty,
                    totalQty: data.totalQty,

                    supplierChallanNumber: data.supplierChallanNumber || null,
                    vehicleNumber: data.vehicleNumber,
                    vehicleName: data.vehicleName,
                    _id: data._id,
                    rejectionNumber: data.rejectionNumber,

                    fromAddress: data.fromAddress,
                    toAddress: data.toAddress,
                    weighingSlip: data.weighingSlip,
                    rejectionPhoto: data.rejectionPhoto,
                    totalWeight: data.totalWeight,
                    totalApproxWeight: data.totalApproxWeight
                })
                setArr(data.arr)
                setFromAddressKey(Date.now())
                setToAddressKey(Date.now())
                setKey(Date.now())

                setWeightArr(data.poWeight)
            }
        }
    }
    useEffect(
        () => {
            calTotalQty()
            proArr = arr
            dispatch({type: 'SET_CHALLAN_ITEMS', data: arr});
        },
        [arr]
    )
    const returnApproxWeight = item => {
        try {
            if (item.type == 'Product') {
                if (item.qty && item.weight) {
                    return parseInt(parseFloat(item.qty) * parseFloat(item.weight))
                } else {
                    return 0;
                }
            } else {
                return item.qty;
            }

        } catch (e) {
            return 0

        }

    }


    const calTotalQty = () => {
        let rQ = 0
        let pQ = 0
        let aW = 0
        _.each(arr, v => {
            if (v.type == 'Product') {
                if (v.qty) {
                    pQ += parseFloat(v.qty)
                }
            }

            if (v.qty && v.weight) {
                aW += parseFloat(parseFloat(v.qty) * parseFloat(v.weight))
            }
        })

        setObj({
            ...obj,
            totalRawQty: (parseFloat(rQ)),
            totalProductQty: parseFloat(parseFloat(pQ)),
            totalQty: parseFloat(parseFloat(rQ + pQ)),
            totalApproxWeight: parseFloat(parseFloat(aW).toFixed(2))
        })
    }

    const saveOnly = async (printRejection) => {
        if (!obj.fromSupplierId) {
            notification.error({
                message: 'Required',
                description: 'Choose From Supplier'
            })
            return
        }
        if (!obj.toSupplierId) {
            notification.error({
                message: 'Required',
                description: 'Choose To Supplier'
            })
            return
        }
        if (!obj.rejectionDate) {
            notification.error({
                message: 'Required',
                description: 'Choose Rejection Date'
            })
            return
        }
        if (!obj.reason) {
            notification.error({message: 'Required', description: 'Enter Reason'})
            return
        }
        if (!obj.rejectionNumber) {
            notification.error({
                message: 'Required',
                description: 'Enter Rejection Number'
            })
            return
        }
        /*if (!obj.totalQty) {
                notification.error({message: 'Required', description: 'Fill All Quantities '});
                return
            }*/
        if (!arr.length) {
            notification.error({
                message: 'Required',
                description: 'Choose Products'
            })
            return
        }

        let findIn = _.find(arr, v => {
            return !v.qty
        })
        if (findIn) {
            notification.error({
                message: 'Required',
                description: `Enter quantity for ${findIn.productName}`
            })
            return
        }


        let findWei = _.find(weightArr, v => {
            return !v.weight
        })
        if (findWei) {
            notification.error({
                message: 'Required',
                description: `Enter weight for ${findWei.productName}`
            })
            return
        }


        setSpinLoader(true)
        let {success, message} = await UpdateAction({
            ...obj,
            arr,
            weightArr
        })
        setSpinLoader(false)
        if (success) {
            notification.success({
                message: 'Success',
                description: 'Rejection Updated'
            })

            if (printRejection) {
                const rejectData = await PrintRejectionAction(obj._id)
                if (rejectData.success) {
                    window.open(rejectData.url)
                }
            }

            dispatch(goBack())
        } else {
            notification.error({message: 'Error', description: message})
        }
    }

    const uploadProps = {
        action: `${API_URL}/filesuploader`,

        onChange(info) {
            if (info.file.status !== 'uploading') {
            }
            if (info.file.status === 'done') {
                setObj({
                    ...obj,
                    weighingSlip: [
                        {
                            uid: info.file.uid,
                            name: info.file.name,
                            path: info.file.response.files[0].path,
                            url: '/downloads/' + info.file.response.files[0].filename
                        }
                    ]
                })
            }
            if (info.file.status === 'removed') {
                setObj({
                    ...obj,
                    weighingSlip: []
                })
            }
        }
    }
    const handlePreview = async file => {
        let url
        if (file && file.response && file.response.files[0].path) {
            file.response.path = file.response.files[0].path.replace(/^public/, '')
            url = `${API_URL}/${file.response.path}`
        } else {
            url = `${API_URL}/${file.url}`
        }

        window.open(url, '_blank')
    }

    const previewColss = [
        {
            title: 'Product',
            key: 'productName',
            dataKey: 'productName',
            render: (val, record) => {
                return (
                    <div>{val} ({record.size} {record.sizeUnit})</div>
                )
            }

        },
        /*  {
              title: 'Size',
              key: 'size',
              dataKey: 'size',

          },*/
        {
            title: 'Qty',
            key: 'qty',
            width: 100,
            render: val => {
                return <Tag color={'green'}>{val}</Tag>
            }
        },


    ]

    const onPreview = () => {

        setShowPreview(true)
    }


    return (
        <PageHeaderWrapper title={`Edit Rejection (${obj.rejectionNumber})`}>
            <Spin spinning={spinLoader}>
                <Card>
                    <Row>
                        <Col className={styles.filterCol}>
                            <b>From Supplier</b>

                            <div>
                                {obj.fromSupplierName}
                            </div>


                        </Col>
                        <Col className={styles.filterCol}>
                            <b>To Supplier</b>
                            <div>
                                {obj.toSupplierName}
                            </div>
                        </Col>
                        <Col className={styles.filterCol}>
                            <b>Rejection Date</b>
                            <br/>

                            <DatePicker
                                format={dateFormat}
                                value={obj && obj.rejectionDate}
                                onChange={e => {
                                    setObj({
                                        ...obj,
                                        rejectionDate: e
                                    })
                                }}
                            />
                        </Col>

                        <Col className={styles.filterCol}>
                            <b>Rejection Number</b>
                            <br/>
                            <Input
                                disabled={true}
                                onChange={e => {
                                    setObj({
                                        ...obj,
                                        rejectionNumber: e.target.value
                                    })
                                }}
                                value={obj.rejectionNumber}
                            />
                        </Col>


                    </Row>
                </Card>
                {showSearch ? (
                    <SearchProduct
                        fromSupplierId={obj.toSupplierId}
                        toSupplierId={obj.fromSupplierId}
                        supplierName={obj.toSupplierName}
                        onSeleced={tempArr => {

                            let testArr = _.cloneDeep(arr)
                            _.each(tempArr, (val, kk) => {
                                let findIn = _.find(testArr, vv => {
                                    // return vv._id == val._id
                                    return (vv.poDocId == val.poDocId && vv.variantId == val.variantId)
                                })

                                if (!findIn) {
                                    testArr.push(val)
                                } else {
                                    findIn.qty = val.qty;
                                }

                            })
                            setArr(testArr)
                            proArr = testArr
                        }}
                        onClose={() => {
                            setSearch(false)
                        }}
                    />
                ) : null}

                <Row>
                    <Col className={styles.addressInp}>
                        <Card>
                            <Text type={'success'}>Sender Address</Text>
                            <Row>
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>Name</b>
                                    </div>

                                    <AutoComplete
                                        key={fromAddressKey}
                                        className={styles.addInput}
                                        options={fromAddress}
                                        defaultValue={
                                            obj && obj.fromAddress && obj.fromAddress.name
                                        }
                                        onChange={e => {
                                            setObj({
                                                ...obj,
                                                fromAddress: {
                                                    name: e,
                                                    mobile:
                                                        obj && obj.fromAddress && obj.fromAddress.mobile,
                                                    email:
                                                        obj && obj.fromAddress && obj.fromAddress.email,
                                                    address:
                                                        obj && obj.fromAddress && obj.fromAddress.address,
                                                    state:
                                                        obj && obj.fromAddress && obj.fromAddress.state,
                                                    city: obj && obj.fromAddress && obj.fromAddress.city,
                                                    pinCode:
                                                        obj && obj.fromAddress && obj.fromAddress.pinCode
                                                }
                                            })
                                        }}
                                        placeholder="Type Billing Name"
                                        onSelect={(e, option) => {
                                            const {info} = option
                                            setObj({
                                                ...obj,
                                                fromAddress: {
                                                    name: info.name,
                                                    mobile: info.mobile,
                                                    email: info.email,
                                                    address: info.address,
                                                    state: info.state,
                                                    city: info.city,
                                                    pinCode: info.pinCode
                                                }
                                            })
                                        }}
                                    />
                                </Col>
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>Mobile</b>
                                    </div>
                                    <Input
                                        onChange={e => {
                                            obj.fromAddress.mobile = e.target.value
                                            setObj({
                                                ...obj
                                            })
                                        }}
                                        value={obj && obj.fromAddress && obj.fromAddress.mobile}
                                    />
                                </Col>
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>Email</b>
                                    </div>
                                    <Input
                                        className={styles.addInput}
                                        onChange={e => {
                                            obj.fromAddress.email = e.target.value
                                            setObj({
                                                ...obj
                                            })
                                        }}
                                        value={obj && obj.fromAddress && obj.fromAddress.email}
                                    />
                                </Col>

                                <Col className={styles.addInp}>
                                    <div>
                                        <b>State</b>
                                    </div>
                                    <Input
                                        className={styles.addInput}
                                        onChange={e => {
                                            obj.fromAddress.state = e.target.value
                                            setObj({
                                                ...obj
                                            })
                                        }}
                                        value={obj && obj.fromAddress && obj.fromAddress.state}
                                    />
                                </Col>
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>City</b>
                                    </div>
                                    <Input
                                        className={styles.addInput}
                                        onChange={e => {
                                            obj.fromAddress.city = e.target.value
                                            setObj({...obj})
                                        }}
                                        value={obj && obj.fromAddress && obj.fromAddress.city}
                                    />
                                </Col>
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>Pin Code</b>
                                    </div>
                                    <Input
                                        className={styles.addInput}
                                        onChange={e => {
                                            obj.fromAddress.pinCode = e.target.value
                                            setObj({...obj})
                                        }}
                                        value={obj && obj.fromAddress && obj.fromAddress.pinCode}
                                    />
                                </Col>

                                <Col className={styles.addInp}>
                                    <div>
                                        <b>Address</b>
                                    </div>
                                    <Input
                                        className={styles.addAddress}
                                        onChange={e => {
                                            obj.fromAddress.address = e.target.value
                                            setObj({...obj})
                                        }}
                                        value={obj && obj.fromAddress && obj.fromAddress.address}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Text type={'warning'}>Receiver Address</Text>
                            <Row>
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>Name</b>
                                    </div>
                                    <AutoComplete
                                        key={toAddressKey}
                                        className={styles.addInput}
                                        options={toAddress}
                                        defaultValue={obj && obj.toAddress && obj.toAddress.name}
                                        placeholder="Type Billing Name"
                                        onChange={e => {
                                            setObj({
                                                ...obj,
                                                toAddress: {
                                                    name: e,
                                                    mobile: obj && obj.toAddress && obj.toAddress.mobile,
                                                    email: obj && obj.toAddress && obj.toAddress.email,
                                                    address:
                                                        obj && obj.toAddress && obj.toAddress.address,
                                                    state: obj && obj.toAddress && obj.toAddress.state,
                                                    city: obj && obj.toAddress && obj.toAddress.city,
                                                    pinCode: obj && obj.toAddress && obj.toAddress.pinCode
                                                }
                                            })
                                        }}
                                        onSelect={(e, option) => {
                                            const {info} = option
                                            setObj({
                                                ...obj,
                                                toAddress: {
                                                    name: info.name,
                                                    mobile: info.mobile,
                                                    email: info.email,
                                                    address: info.address,
                                                    state: info.state,
                                                    city: info.city,
                                                    pinCode: info.pinCode
                                                }
                                            })
                                        }}
                                    />
                                </Col>{' '}
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>Mobile</b>
                                    </div>

                                    <Input
                                        className={styles.addInput}
                                        onChange={e => {
                                            obj.toAddress.mobile = e.target.value
                                            setObj({...obj})
                                        }}
                                        value={obj && obj.toAddress && obj.toAddress.mobile}
                                    />
                                </Col>
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>Email</b>
                                    </div>
                                    <Input
                                        className={styles.addInput}
                                        onChange={e => {
                                            obj.toAddress.email = e.target.value
                                            setObj({...obj})
                                        }}
                                        value={obj && obj.toAddress && obj.toAddress.email}
                                    />
                                </Col>
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>State</b>
                                    </div>
                                    <Input
                                        className={styles.addInput}
                                        onChange={e => {
                                            obj.toAddress.state = e.target.value
                                            setObj({...obj})
                                        }}
                                        value={obj && obj.toAddress && obj.toAddress.state}
                                    />
                                </Col>{' '}
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>City</b>
                                    </div>
                                    <Input
                                        className={styles.addInput}
                                        onChange={e => {
                                            obj.toAddress.city = e.target.value
                                            setObj({...obj})
                                        }}
                                        value={obj && obj.toAddress && obj.toAddress.city}
                                    />
                                </Col>
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>Pincode</b>
                                    </div>
                                    <Input
                                        className={styles.addInput}
                                        onChange={e => {
                                            obj.toAddress.pinCode = e.target.value
                                            setObj({...obj})
                                        }}
                                        value={obj && obj.toAddress && obj.toAddress.pinCode}
                                    />
                                </Col>
                                <Col className={styles.addInp}>
                                    <div>
                                        <b>Address</b>
                                    </div>
                                    <Input
                                        className={styles.addAddress}
                                        onChange={e => {
                                            obj.toAddress.address = e.target.value
                                            setObj({...obj})
                                        }}
                                        rows={3}
                                        value={obj && obj.toAddress && obj.toAddress.address}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <Card
                    title={`Collections (${arr.length})`}
                    extra={
                        <Button size={'small'} onClick={() => setSearch(true)}>
                            <SearchOutlined/> Search Items
                        </Button>
                    }>
                    <TableComp
                        dataSource={arr}
                        extraProps={{scroll: {x: 1500}}}
                        pagination={{
                            defaultPageSize: 200,
                            pageSizeOptions: ['200', '400', '800', '1000'],
                            showSizeChanger: true
                        }}
                        columns={cols}
                    />
                    <Row style={{marginTop: 10}}>
                        <Col col={18}>
                            <b type={'warning'}>Total Approximate Weight : </b>
                        </Col>
                        <Col col={6}>
                            {' '}
                            <b style={{paddingLeft: 10}}> {obj.totalApproxWeight} KG </b>
                        </Col>
                    </Row>
                </Card>

                <Card title={`Actual Weights`}>
                    <ChallanWeight
                        editValue={weightArr}
                        setWeight={(e) => {
                            if (e && e.weightArr) {
                                setWeightArr(e.weightArr)
                            }
                            setObj({
                                ...obj,
                                totalWeight: e.totalWeight
                            })

                        }}

                    />
                </Card>

                <Card>
                    <Row className={styles.noteArea}>
                        <Col>
                            <div>
                                <b>Note if any</b>
                            </div>

                            <TextArea
                                value={obj.notes}
                                style={{width: 300}}
                                onChange={e => {
                                    setObj({
                                        ...obj,
                                        notes: e.target.value
                                    })
                                }}
                                rows={2}
                            />
                        </Col>
                        <Col className={styles.vehicles}>
                            <div>
                                <b>Vehicle Name</b>
                                <Input
                                    value={obj.vehicleName}
                                    onChange={e => {
                                        setObj({
                                            ...obj,
                                            vehicleName: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </Col>
                        <Col className={styles.vehicles}>
                            <div>
                                <b>Vehicle Number</b>
                                <Input
                                    value={obj.vehicleNumber}
                                    onChange={e => {
                                        setObj({
                                            ...obj,
                                            vehicleNumber: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </Col>
                        <Col className={styles.totalQty}>
                            <b>Total Qty : {obj.totalQty} </b>
                        </Col>
                    </Row>

                    <Row gutter={8}>
                        <Col>
                            <div style={{marginTop: 10}}>
                                <b>Weighing Slip</b>
                                <div>
                                    <Upload
                                        onPreview={handlePreview}
                                        {...uploadProps}
                                        maxCount={1}
                                        accept="image/*"
                                        fileList={obj.weighingSlip}>
                                        <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                                    </Upload>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div style={{marginTop: 10}}>
                                <b>Rejection Photo</b>
                                <div>
                                    <Upload
                                        onPreview={handlePreview}
                                        action={`${API_URL}/filesuploader`}
                                        fileList={obj.rejectionPhoto}
                                        onChange={info => {
                                            if (info.file.status === 'done') {
                                                setObj({
                                                    ...obj,
                                                    rejectionPhoto: [
                                                        {
                                                            uid: info.file.uid,
                                                            name: info.file.name,
                                                            path: info.file.response.files[0].path,
                                                            url:
                                                                '/downloads/' +
                                                                info.file.response.files[0].filename
                                                        }
                                                    ]
                                                })
                                            }
                                            if (info.file.status === 'removed') {
                                                setObj({
                                                    ...obj,
                                                    rejectionPhoto: []
                                                })
                                            }
                                        }}
                                        maxCount={1}
                                        accept="image/*">
                                        <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                                    </Upload>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <b>Edit Reason</b>
                            </div>

                            <TextArea
                                value={obj.reason}
                                style={{width: 300}}
                                onChange={e => {
                                    setObj({
                                        ...obj,
                                        reason: e.target.value
                                    })
                                }}
                                rows={2}
                            />
                        </Col>
                        <Col className={styles.filterCol}>
                            <b>Total Weight</b>
                            <br/>
                            <Input
                                type={'number'}
                                disabled={true}
                                onChange={e => {
                                    setObj({
                                        ...obj,
                                        totalWeight: e.target.value
                                    })
                                }}
                                value={obj.totalWeight}
                            />
                        </Col>
                    </Row>
                </Card>
                <Card>
                    <Row className={styles.btnctr}>
                        <Col style={{marginRight: 10}}>
                            <Button

                                onClick={() => {
                                    onPreview()
                                }}
                                type={'secondary'}>
                                <TableOutlined/> Preview Rejection
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                disabled={spinLoader}
                                loading={spinLoader}
                                onClick={() => {
                                    saveOnly(false)
                                }}
                                style={{marginRight: '1em'}}
                                type={'primary'}>
                                <SaveOutlined/> Update Only
                            </Button>
                            <Button
                                disabled={spinLoader}
                                loading={spinLoader}
                                onClick={() => {
                                    saveOnly(true)
                                }}
                                type={'primary'}>
                                <PrinterOutlined/> Update & Print
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Spin>

            <Modal
                title="Preview Rejection Challan"
                centered
                visible={showPreview}
                onOk={() => setShowPreview(false)}
                onCancel={() => setShowPreview(false)}
                width={1400}>
                <div>
                    <Row style={{marginBottom: 10}}>
                        <Col span={16}>
                            <div><b>Supplier</b></div>
                            <div>{obj.toSupplierName}</div>
                            <div>{obj.toAddress ? obj.toAddress.address : ''}</div>
                        </Col>
                        <Col span={4}>
                            <div><b>Challan Number</b></div>

                            <div>{obj.rejectionNumber}</div>
                        </Col>
                        <Col span={4}>
                            <div><b>Total Items</b></div>
                            <div>{obj.totalQty}</div>
                        </Col>
                    </Row>
                    <Divider/>
                    <TableComp
                        dataSource={arr}

                        extraProps={{scroll: {x: true}}}
                        pagination={{
                            defaultPageSize: 500,
                            pageSizeOptions: ['100', '250', '500', '1000'],
                            showSizeChanger: true
                        }}
                        columns={previewColss}
                    />
                </div>
            </Modal>

        </PageHeaderWrapper>
    )
}
export default EditRejection
